import Heading from "../../../../globalComponents/molecules/Heading";

const ProjectHighlight = ({ projectName, highlightData, regNo }) => {
  const KEYS = Object.keys(highlightData);
  return (
    <section className="row mt-4 projectHighlight">
      <Heading
        className="fw-bold"
        text={`Highlight Of The ${projectName}`}
        fontSize="1.25rem"
      />

      {KEYS?.map((key, idx) => {
        return (
          <div key={idx} className="col-md-4  p-2">
            <div className="highlightDetails rounded-4 p-3 text-capitalize">
              <div className="center-item py-1 opacity-75">{key}</div>
              <div className="center-item py-1 fw-bold ">
                {highlightData[key]}
              </div>
            </div>
          </div>
        );
      })}

      <div className="reraBox  px-2">
        <div className="reraId">RERA Number</div>
        <div className="reraNumber"> {regNo}</div>
      </div>
    </section>
  );
};

export default ProjectHighlight;
