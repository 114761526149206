import Heading from "../../../globalComponents/molecules/Heading";
import EthosCard from "./EthosCard/EthosCard";

import { EMPTY_OBJECT } from "../../../../assets/constants";
import "./ethos.css";

export const Ethos = ({ data }) => {
  const { Ethos_Heading, Ethos_Description, Add_Ethos } = data || EMPTY_OBJECT;

  return (
    <div className="mb-5">
      <div className="row">
        <div className="col-lg-3 col-md-12 center-item">
          <div className="ethos-BG">
            <Heading
              text={Ethos_Heading}
              color="#fff"
              fontWeight="700"
              fontSize="36px"
              className="ethos-heading"
            />
            <p className="ehtospara">{Ethos_Description}</p>
            {/* <div>
              <AiOutlineEnvironment
                className="icon iconPlacementEhos"
                size={62}
                color={"#f37535"}
              />
            </div> */}
          </div>
        </div>
        <div className="col-lg-9 col-md-12">
          <div className="row">
            {Add_Ethos?.map((item, idx) => (
              <div key={idx} className="col-6 col-lg-4 col-md-4 my-3 col-sm-6 ">
                <EthosCard data={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Ethos;
