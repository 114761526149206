import { Suspense, useState, lazy, memo } from "react";

import useApiFetcher from "../../hooks/useApiFetcher";
// import PATCH_IMG from "./patch.png";
import { EMPTY_OBJECT } from "../../assets/constants";
import { API_URL, getFullUrl } from "../../assets/constants/apiUrls";
import "./patchButton.css";

const EventPopUp = lazy(() => import("../homePage/EventPopUp"));

const PatchButton = () => {
  const url = getFullUrl(API_URL.HANDLE_EVENT_POP_UP + "?populate=*");
  const [data, isLoading, error] = useApiFetcher(url);

  const [isEventOpen, setIsEventOpen] = useState(false);

  if (!data || isLoading) return null;
  if (error) return <div>{error.message}</div>;

  const {
    eventFormName = "",
    eventStatus,
    patchCardImage,
    eventPopUpImg,
    eventFormLogoImg,
  } = data[0]?.attributes || EMPTY_OBJECT;

  if (!eventStatus) {
    return null;
  }
  const PATCH_IMG1 = patchCardImage?.data?.attributes?.url;
  const EVENT_IMG = eventPopUpImg?.data?.attributes?.url;
  const EVENT_FORM_LOGO_IMG = eventFormLogoImg?.data?.attributes?.url;

  const handleOpen = () => setIsEventOpen(true);
  const handleClose = () => setIsEventOpen(false);

  return (
    <>
      <div className="patchContainer" onClick={handleOpen}>
        <div className="patchCard">
          <img alt="" src={PATCH_IMG1} className="img-fluid" />
        </div>
      </div>
      <Suspense fallback="">
        {isEventOpen && (
          <EventPopUp
            isStatus={isEventOpen}
            onClose={handleClose}
            eventBanner={EVENT_IMG}
            eventFormName={eventFormName}
            eventFormLogo={EVENT_FORM_LOGO_IMG}
          />
        )}
      </Suspense>
    </>
  );
};

export default memo(PatchButton);
