import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import Slider from "react-slick";
import { FcGallery } from "react-icons/fc";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import React, { useRef, useEffect, useMemo } from "react";

import ProjectSliderCard from "./ProjectSliderCard";
import ProjectShareBtn from "./ProjectShareBtn";

import { EMPTY_ARRAY } from "../../../../../assets/constants";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// import WishListBtn from './../../../../globalComponents/WishListBtn/WishListBtn';

// const ProjectSlider = ({ imagesData = EMPTY_ARRAY }) => {
//   const settings = {
//     // dots: true,
//     lazyLoad: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     initialSlide: 2,
//   };

//   const sliderRef = useRef(null);

//   const handlePreviousBtn = () => sliderRef.current.slickPrev(); // Previous button logic
//   const handleNextBtn = () => sliderRef.current.slickNext();
//   const totalImages = imagesData?.length;

//   return (
//     <div className="slider-container">
//       <Slider {...settings} ref={sliderRef}>
//         {imagesData?.map((imgData, idx) => (
//           <ProjectSliderCard imageData={imgData} key={idx} />
//         ))}
//       </Slider>
//       <div className="controlBtnBox">
//         <div className="leftBtn" onClick={handlePreviousBtn}>
//           <FaChevronLeft />
//         </div>
//         <div className="rightBtn" onClick={handleNextBtn}>
//           <FaChevronRight />
//         </div>
//       </div>

//       <div className="controlBtn">
//         <div className="px-lg-4">
//           <ProjectShareBtn className="shareBtn" />
//         </div>

//         <div className="d-flex">
//           <div className="sliderImgCountBox">
//             <FcGallery fontSize={"1.5rem"} className="galleryIconImg" />{" "}
//             {totalImages}
//             {" Photos"}
//           </div>
//           {/* <div>Wishlist</div> */}
//         </div>
//       </div>
//     </div>
//   );
// };

const ProjectSlider = ({ imagesData = EMPTY_ARRAY }) => {
  const sliderRef = useRef(null);
  const containerRef = useRef(null);

  const handlePreviousBtn = () => sliderRef.current.slickPrev();
  const handleNextBtn = () => sliderRef.current.slickNext();
  const totalImages = imagesData?.length;
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const handleModalClose = () => setIsModalVisible(false);
  // const handleModalOpen = () => setIsModalVisible(true);

  // Fancybox binding
  useEffect(() => {
    const container = containerRef.current;
    const delegate = "[data-fancybox]";
    const options = {
      // You can add custom Fancybox options here (optional)
    };

    NativeFancybox.bind(container, delegate, options);

    // Cleanup Fancybox on unmount
    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  }, []);

  const settings = useMemo(() => {
    return {
      lazyLoad: true,
      infinite: imagesData.length > 1,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
    };
  }, [imagesData]);

  return (
    <div className="slider-container">
      {/* Fancybox container ref */}
      <div ref={containerRef}>
        <Slider {...settings} ref={sliderRef}>
          {imagesData?.map((imgData, idx) => (
            <div key={idx}>
              {/* Fancybox Trigger */}
              {/* Clicking this will open all images in the group */}
              <a data-fancybox="gallery" href={imgData.img}>
                <ProjectSliderCard imageData={imgData} />
              </a>
              {/* Hidden multiple images for Fancybox gallery */}
            </div>
          ))}
        </Slider>
        {<MemoizedPopUpSlider imagesData={imagesData} />}
      </div>

      {/* <GallerySlider
        images={imagesData}
        handleModalClose={handleModalClose}
        visibility={isModalVisible}
      /> */}

      <div className="controlBtnBox">
        <div className="leftBtn" onClick={handlePreviousBtn}>
          <FaChevronLeft />
        </div>
        <div className="rightBtn" onClick={handleNextBtn}>
          <FaChevronRight />
        </div>
      </div>
      <div className="controlBtn">
        <div className="px-lg-4">
          <ProjectShareBtn className="shareBtn" />
        </div>

        <div className="d-flex">
          <div
            className="sliderImgCountBox"
            // onClick={handleModalOpen}
          >
            <FcGallery fontSize={"1.5rem"} className="galleryIconImg" />{" "}
            {totalImages}
            {" Photos"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSlider;

export const PopUpSlider = ({ imagesData, isFancyboxOpen }) => {
  // if (!isFancyboxOpen) {
  //   return null;
  // }
  // console.log("popUp render");
  return (
    <>
      {imagesData.map((innerImgData, innerIdx) => (
        <a
          key={innerIdx}
          data-fancybox="gallery"
          href={innerImgData.img}
          style={{ display: "none" }} // Hide these from view, but they'll appear in the Fancybox gallery
        >
          <img src={innerImgData.img} alt={`${innerIdx}`} loading="lazy" />
        </a>
      ))}
    </>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.imagesData) ===
    JSON.stringify(nextProps.imagesData)
  );
};

export const MemoizedPopUpSlider = React.memo(PopUpSlider, arePropsEqual);
