import { memo } from "react";

import Heading from "../../../globalComponents/molecules/Heading";
import ReadMore from "../../../globalComponents/molecules/ReadMore";

import "./awardCard.css";

const readMorenBtn = {
  fontSize: ".75rem",
  padding: ".25rem",
  background: "none",
};

const AwardCard = memo(({ awardData }) => {
  const { img, title, description } = awardData;
  return (
    <div className="col-md-3 col-sm-6 col-xs-12 p-2">
      {/* col-4 col-lg-3 col-sm-4 col-xs-12 */}
      <div className="awardCard">
        <div className="award-img">
          <img
            className="img-fluid w-100"
            src={img}
            alt={`Award img`}
            loading="lazy"
          />

          {title && (
            <Heading
              text={title}
              fontSize="1rem my-1"
              lineHeight="1.25rem"
              showLine="1"
              className="fw-bold overflow-hidden  "
            />
          )}
          {description && (
            <ReadMore
              text={description}
              fontSize=".75rem"
              // className="overflow-hidden"
              lineHeight="1rem"
              showLine="3"
              btnStyle={readMorenBtn}
            />
          )}
        </div>
      </div>
    </div>
  );
});
export default AwardCard;
