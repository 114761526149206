import { toast } from "react-toastify";
import { FaHandshake } from "react-icons/fa6";
import { useState } from "react";

import Heading from "./../../../../globalComponents/molecules/Heading";
import ButtonDarkBlue from "../../../../globalComponents/molecules/ButtonDarkBlue";
import SelectOption from "../../../../globalComponents/molecules/SelectOption";
import InputCustom from "../../../../globalComponents/molecules/InputCustom";
import PopUpCard from "../../../../globalComponents/PopUpCard/PopUpCard";
import ThankYouMessage from "../../../../globalComponents/ThankYouMessage";

import validateForm from "./validation.helper";
import { postMethod } from "../../../../../services";
import { EMPTY_OBJECT } from "../../../../../assets/constants";
import { API_URL, getFullUrl } from ".././../../../../assets/constants/apiUrls";
import CAREER_FORM from "./careerOptions.constant";
import {
  PROCESS_MESSAGE,
  SUCCESS_MESSAGES,
  ERROR_MESSAGE,
} from "../../../../../assets/constants/messages";
import { closeBtnStyle } from "../../../../styles/globalStyle";
import "./careerForm.css";

const popPupStyle = {
  flexDirection: "column",
  top: 0,
  left: 0,
  height: "100%",
};

const CareerFormPopUp = ({ isOpen, onClose, formName }) => {
  const INITIAL_FORM_VALUES = {
    name: "",
    phone: "",
    email: "",
    location: "",
    expectedPackage: "",
    currentPackage: "",
    experience: "",
    preferLocation: "",
    jobTitle: formName.trim(),
    photo: null,
    file: null,
  };

  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
  const [errors, setErrors] = useState({});
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(false);

  const {
    name,
    phone,
    email,
    location,
    preferLocation,
    expectedPackage,
    currentPackage,
    experience,
    photo,
    file,
  } = formValues || EMPTY_OBJECT;

  const handleForm = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm(formValues);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    const postUrl = getFullUrl(API_URL.CAREER_FORM);
    const formData = new FormData();

    const payLoad = { ...formValues };
    formData.append("data", JSON.stringify(payLoad));
    if (photo) formData.append("files.photo", photo);
    if (file) formData.append("files.file", file);

    try {
      setIsBtnDisable(true);
      const response = await postMethod(
        postUrl,
        formData,
        SUCCESS_MESSAGES.CAREER_FORM_SUBMISSION
      );
      if (response) {
        // toast.success("Career Form Submitted");
        setIsBtnDisable(false);
        setIsSuccessfullySubmitted(true);
        setFormValues(INITIAL_FORM_VALUES);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGE.CAREER_FORM_SUBMISSION);
      setIsBtnDisable(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormValues({ ...formValues, [name]: files[0] });
  };

  const handleThankYouComplete = () => {
    setIsSuccessfullySubmitted(false);
    onClose();
  };

  return (
    <PopUpCard
      isOpen={isOpen}
      onClose={onClose}
      className="center-item px-3"
      style={popPupStyle}
      removeBtn={false}
    >
      <div className="career-form position-hc px-3">
        <button style={closeBtnStyle} onClick={onClose}>
          X
        </button>
        <div className="careerFormHeading">
          <div className="px-3 fs-2 text-light center-item">
            <FaHandshake />
          </div>
          <Heading
            text={"Job Application Form"}
            color="#fff"
            fontSize="1.5rem"
            className="fs-6 fs-lg-1 fs-md-1"
          />
        </div>

        <form className="row" onSubmit={handleForm}>
          <div className="col-md-6">
            <InputCustom
              className="px-3 rounded-1"
              placeholder={"Full Name"}
              name="name"
              value={name}
              onChange={handleInputChange}
              // required
            />
            {errors.name && <div className="alertMsg">{errors.name}</div>}
          </div>

          <div className="col-md-6">
            <InputCustom
              className="px-3 rounded-1"
              name="phone"
              value={phone}
              placeholder={"Contact No."}
              onChange={handleInputChange}
              // required
            />
            {errors.phone && <div className="alertMsg">{errors.phone}</div>}
          </div>

          <div className="col-md-6">
            <InputCustom
              className="px-3 rounded-1"
              placeholder={"Email"}
              name="email"
              value={email}
              onChange={handleInputChange}
            />
            {errors.email && <div className="alertMsg">{errors.email}</div>}
          </div>

          <div className="col-md-6">
            {/* <SelectOption
              className="px-3 rounded-1"
              selectText={"Select Experience"}
              data={CAREER_FORM?.EXPERIENCE_OPTIONS}
              name="experience"
              value={experience}
              onChange={handleInputChange}
            /> */}
            <InputCustom
              className="px-3 rounded-1"
              placeholder={"Total Experience In Years"}
              name="experience"
              value={experience}
              onChange={handleInputChange}
              type="number"
            />
            {errors.experience && (
              <div className="alertMsg">{errors.experience}</div>
            )}
          </div>

          <div className="col-md-6">
            <InputCustom
              className="px-3 rounded-1"
              placeholder="Current CTC In Lakhs"
              name="currentPackage"
              value={currentPackage}
              type="number"
              onChange={handleInputChange}
            />
            {errors.currentPackage && (
              <div className="alertMsg">{errors.currentPackage}</div>
            )}
          </div>

          <div className="col-md-6">
            {/* <SelectOption
              className="px-3 rounded-1"
              data={CAREER_FORM?.CURRENT_PACKAGES_OPTIONS}
              selectText={"Select Package"}
              name="expectedPackage"
              value={expectedPackage}
              onChange={handleInputChange}
            /> */}
            <InputCustom
              className="px-3 rounded-1"
              placeholder="Expected CTC In Lakhs"
              name="expectedPackage"
              value={expectedPackage}
              type="number"
              onChange={handleInputChange}
            />
            {errors.expectedPackage && (
              <div className="alertMsg">{errors.expectedPackage}</div>
            )}
          </div>

          <div className="col-md-6">
            <InputCustom
              className="px-3 rounded-1"
              placeholder={"Your Current Location"}
              name="location"
              value={location}
              onChange={handleInputChange}
            />
            {errors.location && (
              <div className="alertMsg mb-2">{errors.location}</div>
            )}
          </div>

          <div className="col-md-6">
            {/* <SelectOption
              className="px-3 rounded-1"
              selectText={"Select Job Title"}
              data={CAREER_FORM?.JOB_TITLE_OPTIONS}
              name="jobTitle"
              value={jobTitle}
              onChange={handleInputChange}
            /> */}
            <SelectOption
              className="px-3 rounded-1"
              selectText={"Select Preferred Job Location"}
              data={CAREER_FORM?.LOCATIONS_OPTIONS}
              name="preferLocation"
              value={preferLocation}
              onChange={handleInputChange}
            />
            {errors.preferLocation && (
              <div className="alertMsg mb-1">{errors.preferLocation}</div>
            )}
          </div>

          <div className="row p-0 m-0">
            <div className="col-md-6">
              <div className="selectFileBox">
                <label>Upload Your Photo</label>
                <InputCustom
                  className="px-3 rounded-1 inputFile"
                  placeholder={"Latest Passport Size Photo"}
                  type="file"
                  name="photo"
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </div>
              {errors.photo && (
                <div className="alertMsg my-1">{errors.photo}</div>
              )}
            </div>
            <div className="col-md-6">
              <div className="selectFileBox">
                <label> Upload Your Resume</label>
                <InputCustom
                  className="px-3 rounded-1 inputFile"
                  placeholder={"Upload CV*"}
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                />
              </div>
              {errors.file && (
                <div className="alertMsg my-1">{errors.file}</div>
              )}
            </div>
          </div>

          <ButtonDarkBlue
            name={
              isBtnDisable
                ? PROCESS_MESSAGE.FORM_SUBMISSION_IN_PROGRESS
                : "Submit"
            }
            className={"w-50 center-item rounded-1"}
            style={{
              position: "relative",
              left: "50% ",
              transform: "translateX(-50%)",
              backgroundColor: "#000",
            }}
            disabled={isBtnDisable}
          />
        </form>
        {isSuccessfullySubmitted && (
          <ThankYouMessage onComplete={handleThankYouComplete} />
        )}
      </div>
    </PopUpCard>
  );
};

export default CareerFormPopUp;
