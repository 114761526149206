import { EMPTY_OBJECT } from "../../../assets/constants";
import { API_URL, getFullUrl } from "../../../assets/constants/apiUrls";
import useApiFetcher from "../../../hooks/useApiFetcher";
import MetaTagHelmet from "../../globalComponents/MetaTagHelmet/MetaTagHelmet";
import Heading from "../../globalComponents/molecules/Heading";
import "./termsCondition.css";

const TermsAndConditions = () => {
  const [termAndCondition, error, isLoading] = useApiFetcher(
    getFullUrl(API_URL?.TERM_AND_CONDITIONS)
  );
  if (error) return <div>{error?.message}</div>;
  if (isLoading) return <div>Loading...</div>;
  if (!termAndCondition) return <div>No Data</div>;

  const { Meta_Title = "", Meta_Description = "" } =
    termAndCondition?.attributes || EMPTY_OBJECT;

  return (
    <section className="container">
      <MetaTagHelmet title={Meta_Title} description={Meta_Description} />
      <div className="termsConditionContainer">
        <ul>
          <Heading
            text="1. GENERAL"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />

          <li>
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures.
          </li>

          <li>
            This document is published in accordance with the provisions of Rule
            3 (1) of the Information Technology (Intermediaries guidelines)
            Rules, 2011 that require publishing the rules and regulations,
            privacy policy and Terms of Use for access or usage of
            www.wealth-clinic.com
          </li>

          <li>
            For the purpose of these Terms of Use ("Terms"), wherever the
            context so requires,
          </li>

          <li>
            <p>
              For the purpose of these Terms of Use ("Terms"), wherever the
              context so requires,
            </p>

            <ul className="U-roman">
              <li>
                The term 'You' & 'User' shall mean any legal person or entity
                accessing or using the services provided on this Website, who is
                competent to enter into binding contracts, as per the provisions
                of the Indian Contract Act, 1872;
              </li>

              <li>
                The terms 'We', 'Us' & 'Our' shall mean the Website and/or the
                Company, as the context so requires.
              </li>

              <li>
                The terms 'Party' & 'Parties' shall respectively be used to
                refer to the User and the Company individually and collectively,
                as the context so requires
              </li>
            </ul>
          </li>

          <li>
            The headings of each section in these Terms are only for the purpose
            of organizing the various provisions under these Terms in an orderly
            manner, and shall not be used by either Party to interpret the
            provisions contained herein in any manner. Further, it is
            specifically agreed to by the Parties that the headings shall have
            no legal or contractual value.
          </li>

          <li>
            The use of the Website by the User is solely governed by these Terms
            as well as the Privacy Policy ("Policy", available at website, and
            any modifications or amendments made thereto by the Company from
            time to time, at its sole discretion. Visiting the home page of the
            Website and/or using any of the services provided on the Website
            shall be deemed to signify the User's unequivocal acceptance of
            these Terms and the aforementioned Policy, and the User expressly
            agrees to be bound by the same. The User expressly agrees and
            acknowledges that the Terms and Policy are co-terminus, and that
            expiry / termination of either one will lead to the termination of
            the other, save as provided in Section 3 hereunder.
          </li>

          <li>
            The User unequivocally agrees that these Terms and the
            aforementioned Policy constitute a legally binding agreement between
            the User and the Company, and that the User shall be subject to the
            rules, guidelines, policies, terms, and conditions applicable to any
            service that is provided by the Website, and that the same shall be
            deemed to be incorporated into these Terms, and shall be treated as
            part and parcel of the same. The User acknowledges and agrees that
            no signature or express act is required to make these Terms and the
            Policy binding on the User, and that the User's act of visiting the
            any part of the Website constitutes the User's full and final
            acceptance of these Terms and the aforementioned Policy.
          </li>

          <li>
            The Company reserves the sole and exclusive right to amend or modify
            these Terms without any prior permission or intimation to the User,
            and the User expressly agrees that any such amendments or
            modifications shall come into effect immediately. The User has a
            duty to periodically check the terms and stay updated on its
            requirements. If the User continues to use the Website following
            such a change, the User will be deemed to have consented to any and
            all amendments / modifications made to the Terms. In so far as the
            User complies with these Terms, he/she is granted a personal,
            non-exclusive, non-transferable, revocable, limited privilege to
            enter and use the Website.
          </li>
        </ul>

        <ul className="del-list-style">
          <Heading
            text="2. THE SERVICES:"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />

          <li>
            We are a professional real estate consultancy company. Below
            mentioned is the list of services:
          </li>
          <li>
            Property Identification- We provide assistance in property search
            through our technical research and analysis to match customer
            requirement.
          </li>

          <li>
            Transactional Support - The sales team helps during Site visits,
            Pricing / Inventory Negotiation.
          </li>

          <li>
            Financial & Administrative Support - We have a dedicated team which
            aids the customer for Mortgage & Financial services, Administrative
            & Legal support.
          </li>

          <li>
            Product Portfolio - We cater into all kinds of properties;
            categorized as Residential (primary & resale), Commercial, Leasing,
            Land, Affordable Housing, Investor Projects, Structured offerings
            etc.
          </li>
          <li>
            Portfolio Structuring & Management- We help in diversifying our
            clients' real estate portfolios and are pioneers in bringing
            Properties with the best payout options to Indian investors.
          </li>
        </ul>

        <ul>
          <Heading
            text="3. ELIGIBILITY"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />
          <p>
            The User represents and warrants that he/she is competent and
            eligible to enter into legally binding agreements and that he/she
            has the requisite authority to bind himself/herself to these Terms,
            as determined solely by the provisions of the Indian Contract Act,
            1872. The User may not use this Website if he/she is not competent
            to contract under the Indian Contract Act, 1872, or is disqualified
            from doing so by any other applicable law, rule or regulation
            currently in force.
          </p>
        </ul>

        <ul>
          <Heading
            text="4. TERM"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />
          <p>
            These Terms shall continue to form a valid and binding contract
            between the Parties, and shall continue to be in full force and
            effect until:
          </p>

          <li>The User continues to access and use the Website; or</li>
          <li>
            The Transaction between the Parties, if any, concludes to the
            satisfaction of both Parties; Whichever is longer. The Parties agree
            that certain portions of these Terms ("Sections"), such as Sections
            12, 13, 14, & 16, shall continue to remain in full force and effect
            indefinitely, even after the expiry or termination of these Terms as
            contemplated herein.
          </li>
        </ul>

        <ul>
          <Heading
            text="5. TERMINATION"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />

          <li>
            The Company reserves the right, in its sole discretion, to
            unilaterally terminate the User's access to the products and
            services offered on the Website, or any portion thereof, at any
            time, without notice or cause. The User shall continue to be bound
            by these Terms, and it is expressly agreed to by the Parties that
            the User shall not have the right to terminate these Terms till the
            expiry of the same, as described in Clause 3 hereinabove.
          </li>
        </ul>

        <ul>
          <Heading
            text="6. ONLINE SERVICE PLATFORM"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />

          <li>
            The Website is an online service platform that gives advises and
            recommendations regarding real estate properties across the globe
          </li>
        </ul>

        <ul>
          <Heading
            text="7. COMMUNICATION"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />

          <p>
            By using this Website, and providing his/her contact information to
            the Company through the Website, the User hereby agrees and consents
            to receiving calls, autodialed and/or pre-recorded message calls,
            e-mails and SMSs from the Company and/or any of its affiliates or
            partners at any time, subject to the Policy. In the event that the
            User wishes to stop receiving any such marketing or promotional
            calls / email messages / text messages, he/she may inform the
            concerned. The User agrees and acknowledges that it may take up to
            seven (7) business days for the Company to give effect to such a
            request by the User.
          </p>
          <p>
            The User expressly agrees that notwithstanding anything contained
            herein above, he/she may be contacted by the Company or any of its
            affiliates / partners relating to any service availed of by the User
            on the Website or anything pursuant thereto.
          </p>
          <p>
            It is expressly agreed to by the Parties that any information shared
            by the User with the Company shall be governed by the Policy
          </p>
        </ul>

        <ul>
          <Heading
            text="8. COMMUNICATION"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />
          <p>
            The use of this Website by the User, such as browsing the Website is
            free of cost. The User is only required to pay for the services
            availed by the User of the Website. However, the Company reserves
            the right to amend this no-fee policy and charge the User for any or
            all services offered / rendered. In such an event, the User will be
            intimated of the same when he/she attempts to access the Website,
            and the User shall have the option of declining to avail of the
            services offered on the Website. Any such change, if made, shall
            come into effect immediately upon such change being notified to the
            User, unless specified otherwise.
          </p>
        </ul>
        <ul>
          <Heading
            text="9. USER OBLIGATIONS"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />
          <p>
            The User agrees and acknowledges that he/she is a restricted user of
            this Website, and that he/she:
          </p>

          <li>
            Is bound not to cut, copy, distribute, modify, recreate, reverse
            engineer, distribute, disseminate post, publish or create derivative
            works from, transfer, or sell any information or software obtained
            from the Website. Any such use / limited use of the Website will
            only be allowed with the prior express written permission of the
            Company. For the removal of doubt, it is clarified that unlimited or
            wholesale reproduction, copying of the content for commercial or
            non-commercial purposes and unwarranted modification of data and
            information contained on the Website is expressly prohibited.
          </li>
          <li>
            Agrees not to access (or attempt to access) the Website and/or the
            materials or services by any means other than through the interface
            provided by the Website. The use of deep-link, robot, spider or
            other automatic device, program, algorithm or methodology, or any
            similar or equivalent manual process, to access, acquire, copy or
            monitor any portion of the Website or its content, or in any way
            reproduce or circumvent the navigational structure or presentation
            of the Website, materials or any content, or to obtain or attempt to
            obtain any materials, documents or information through any means not
            specifically made available through the Website will lead to
            suspension or termination of the User's access to the Website, as
            detailed in Section 11 herein below. The User acknowledges and
            agrees that by accessing or using the Website or any of the services
            provided therein, he/she may be exposed to content that he/she may
            consider offensive, indecent or otherwise objectionable. The Company
            disclaims any and all liabilities arising in relation to such
            offensive content on the Website. The User expressly agrees and
            acknowledges that the products / services displayed on the Website
            are not owned by the Company/Website, and that the same are the
            exclusive property of certain third parties who have chosen to
            market their products through the Company's Website, and that the
            Company/Website is in no way responsible for the content of the
            same. The User may however report any such offensive or
            objectionable content, which the Company may then remove from the
            Website, at its sole discretion.
          </li>
          <li>
            n places where Website permits the User to post or upload
            data/information, the User undertakes to ensure that such material
            is not offensive or objectionable, and is in accordance with
            applicable laws. The User expressly agrees that any such material
            that is deemed to be objectionable/offensive may be removed from the
            Website immediately and without notice, and further that the User's
            access to the Website may also be permanently revoked, at the sole
            discretion of the Company.
          </li>

          <li>
            <p>Further undertakes not to:</p>
            <ul className="U-roman">
              <li>
                Abuse, harass, threaten, defame, disillusion, erode, abrogate,
                demean or otherwise violate the legal rights of any other person
                or entity;
              </li>
              <li>
                Engage in any activity that interferes with or disrupts access
                to the Website or the services provided therein (or the servers
                and networks which are connected to the Website);
              </li>
              <li>
                Impersonate any person or entity, or falsely state or otherwise
                misrepresent his/her affiliation with a person or entity;
              </li>
              <li>
                Publish, post, disseminate, any information which is grossly
                harmful, harassing, blasphemous, defamatory, obscene,
                pornographic, pedophilic, libelous, invasive of another's
                privacy, hateful, or racially, ethnically objectionable,
                disparaging, relating or encouraging money laundering or
                gambling, or otherwise unlawful in any manner whatever under any
                law, rule or regulation currently in force; or unlawfully
                threatening or unlawfully harassing including but not limited to
                "indecent representation of women" within the meaning of the
                Indecent Representation of Women (Prohibition) Act, 1986;
              </li>

              <li>
                Post any image/file/data that infringes the copyright, patent or
                trademark of another person or legal entity;
              </li>

              <li>
                Upload or distribute files that contain viruses, corrupted
                files, or any other similar software or programs that may damage
                the operation of the Website;
              </li>

              <li>
                Download any file posted/uploaded by another user of the Website
                that the User is aware, or should reasonably be aware, cannot be
                legally distributed in such a manner;
              </li>

              <li>
                Probe, scan or test the vulnerability of the Website or any
                network connected to the Website, nor breach the security or
                authentication measures on the Website or any network connected
                to the Website. The User may not reverse look-up, trace or seek
                to trace any information relating to any other user of, or
                visitor to, the Website, or any other customer of the Website,
                including any user account maintained on the Website not
                operated/managed by the User, or exploit the Website or
                information made available or offered by or through the Website,
                in any manner;
              </li>

              <li>
                Disrupt or interfere with the security of, or otherwise cause
                harm to, the Website, systems resources, accounts, passwords,
                servers or networks connected to or accessible through the
                Websites or any affiliated or linked websites;
              </li>

              <li>Collect or store data about other users of the Website.</li>

              <li>
                Use the Website or any material or content therein for any
                purpose that is unlawful or prohibited by these Terms, or to
                solicit the performance of any illegal activity or other
                activity which infringes the rights of this Website or any other
                third party (ies);
              </li>

              <li>
                Violate any code of conduct or guideline which may be applicable
                for or to any particular product or service offered on the
                Website;
              </li>

              <li>
                Violate any applicable laws, rules or regulations currently in
                force within or outside India;
              </li>

              <li>
                Violate any portion of these Terms or the Policy, including but
                not limited to any applicable additional terms of the Website
                contained herein or elsewhere, whether made by amendment,
                modification, or otherwise;
              </li>

              <li>
                Threaten the unity, integrity, defense, security or sovereignty
                of India, friendly relations with foreign states, or public
                order, or cause incitement to the commission of any cognizable
                offence, or prevent the investigation of any offence, or insult
                any other nation.
              </li>

              <li>
                Publish, post, or disseminate information that is false,
                inaccurate or misleading;
              </li>

              <li>
                Directly or indirectly offer, attempt to offer, trade, or
                attempt to trade, any item the dealing of which is prohibited or
                restricted in any manner under the provisions of any applicable
                law, rule, regulation or guideline for the time being in force.
              </li>

              <li>
                Commit any act that causes the Company to lose (in whole or in
                part) the services of its internet service provider ("ISP") or
                in any manner disrupts the services of any other
                supplier/service provider of the Company/Website;
              </li>

              <li>
                Engage in advertising to, or solicitation of, other users of the
                Website to buy or sell any products or services not currently
                displayed on the Website. The User may not transmit any chain
                letters or unsolicited commercial or junk email/messages to
                other users via the Website. It shall be a violation of these
                Terms to use any information obtained from the Website in order
                to harass, abuse, or harm another person, or in order to
                contact, advertise to, solicit, or sell to another user of the
                Website without the express prior written consent of the
                Company.
              </li>

              <li>
                The User hereby expressly authorizes the Company/Website to
                disclose any and all information relating to the User in the
                possession of the Company/Website to law enforcement or other
                government officials, as the Company may in its sole discretion,
                believe necessary or appropriate in connection with the
                investigation and/or resolution of possible crimes, especially
                those involve personal injury and theft / infringement of
                intellectual property. The User further understands that the
                Company/Website might be directed to disclose any information
                (including the identity of persons providing information or
                materials on the Website) as necessary to satisfy any judicial
                order, law, regulation or valid governmental request.
              </li>

              <li>
                The User expressly agrees and acknowledges that the
                Company/Website has no obligation to monitor the materials
                posted on the Website, but that it has the right to remove or
                edit any content that in its sole discretion violates, or is
                alleged to violate, any applicable law or either the spirit or
                letter of these Terms. Notwithstanding this right, the User
                remains solely responsible for the content of the materials
                posted on the Website by him/her. In no event shall the
                Company/Website assume or be deemed to have any responsibility
                or liability for any content posted, or for any claims, damages
                or losses resulting from use of any such content and/or the
                appearance of any content on the Website. The User hereby
                represents and warrants that he/she has all necessary rights in
                and to all content provided as well as all information contained
                therein, and that such content does not infringe any proprietary
                or other rights of any third party(ies), nor does it contain any
                libelous, tortuous, or otherwise unlawful or offensive material,
                and the User hereby accepts full responsibility for any
                consequences that may arise due to the publishing of any such
                material on the Website.
              </li>
            </ul>
          </li>
        </ul>

        <ul>
          <Heading
            text="10. SUSPENSION OF USER ACCESS AND ACTIVITY"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />
          <p>
            Notwithstanding other legal remedies that may be available to it,
            the Company may in its sole discretion limit the User's access and/
            or activity by immediately removing the User's access credentials
            either temporarily or indefinitely, or suspend / terminate the
            User's membership, and/or refuse to provide User with access to the
            Website, without being required to provide the User with notice or
            caus
          </p>

          <li>If the User is in breach any of these Terms or the Policy;</li>
          <li>
            If the User has provided wrong, inaccurate, incomplete or incorrect
            information;
          </li>
          <li>
            If the User's actions may cause any harm, damage or loss to the
            other users or to the Website/Company, at the sole discretion of the
            Company.
          </li>
        </ul>

        <ul>
          <Heading
            text="11. INDEMNITY AND LIMITATIONS"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />

          <p>
            The User hereby expressly agrees to defend, indemnify and hold
            harmless the Website and the Company, its employees, directors,
            officers, agents and their successors and assigns and against any
            and all claims, liabilities, damages, losses, costs and expenses,
            including attorney's fees, caused by or arising out of claims based
            upon the User's actions or inactions, including but not limited to
            any warranties, representations or undertakings, or in relation to
            the non-fulfilment of any of the User's obligations under this
            Agreement, or arising out of the User's infringement of any
            applicable laws, rules and regulations, including but not limited to
            infringement of intellectual property rights, payment of statutory
            dues and taxes, claims of libel, defamation, violation of rights of
            privacy or publicity, loss of service by other subscribers, or the
            infringement of any other rights of a third party.
          </p>

          <p>
            In no event shall the Company/Website be liable to compensate the
            User or any third party for any special, incidental, indirect,
            consequential or punitive damages whatsoever, including those
            resulting from loss of use, data or profits, whether or not
            foreseeable, and whether or not the Company/Website had been advised
            of the possibility of such damages, or based on any theory of
            liability, including breach of contract or warranty, negligence or
            other tortuous action, or any other claim arising out of or in
            connection with the User's use of or access to the Website and/or
            the products, services or materials contained therein.
          </p>

          <p>
            The limitations and exclusions in this section apply to the maximum
            extent permitted by applicable law, and the Parties expressly agree
            that in the event of any statute, rule, regulation or amendment
            coming into force that would result in the Company/Website incurring
            any form of liability whatsoever, these Terms and the Policy will
            stand terminated one (1) day before the coming into effect of such
            statute, rule, regulation or amendment. It is further agreed to by
            the Parties that the contents of this Section shall survive even
            after the termination or expiry of the Terms and/or Policy.
          </p>
        </ul>

        <ul>
          <Heading
            text="12. INTELLECTUAL PROPERTY RIGHTS"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />

          <p>
            Unless expressly agreed to in writing, nothing contained herein
            shall give the User a right to use any of the Website's trade names,
            trademarks, service marks, logos, domain names, information,
            questions, answers, solutions, reports and other distinctive brand
            features, save according to the provisions of these Terms. All
            logos, trademarks, brand names, service marks, domain names,
            including material, designs, and graphics created by and developed
            by the Website and other distinctive brand features of the Website
            are the property of the Company. Furthermore, with respect to the
            Website created by the Company, the Company shall be the exclusive
            owner of all the designs, graphics and the like, related to the
            Website.
          </p>

          <p>
            The User may not use any of the intellectual property displayed on
            the Website in any manner that is likely to cause confusion among
            existing or prospective users of the Website, or that in any manner
            disparages or discredits the Company/Website, to be determined in
            the sole discretion of the Company.
          </p>

          <p>
            The User is aware that the products displayed on the Website are the
            artistic creations of their respective owners, and that all
            intellectual property, including but not limited to copyrights,
            relating to said products resides with the said owners, and that at
            no point does any such intellectual property stand transferred from
            the aforementioned creators to the Website/Company, or to the User.
            The User is aware that the Company merely provides a platform
            through which the aforementioned products are listed for sale to the
            users of the Website, and the neither the Company nor the Website
            owns any of the intellectual property relating to the products
            displayed on the Website.
          </p>

          <p>
            The User is further aware that any reproduction or infringement of
            the intellectual property of the aforementioned owners by the User
            will result in legal action being initiated against the User by the
            respective owners of the intellectual property so reproduced /
            infringed upon. It is agreed to by the Parties that the contents of
            this Section shall survive even after the termination or expiry of
            the Terms and/or Policy.
          </p>
        </ul>

        <ul>
          <Heading
            text="13. DISLAIMER OF WARRANTIES AND LIABILITIES"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />

          <li>
            Except as otherwise expressly stated on the Website, all
            products/services offered on the Website are offered on an "as is"
            basis without any warranty whatsoever, either express or implied.
          </li>

          <li>
            The Company/Website makes no representations, express or implied,
            including without limitation implied warranties of merchantability
            and fitness of a product for a particular purpose.
          </li>

          <li>
            The User agrees and undertakes that he/she is accessing the Website
            and transacting at his/her sole risk and are that he/she is using
            his/her best and prudent judgment before purchasing any
            product/service listed on the Website, or accessing/using any
            information displayed thereon.
          </li>

          <li>
            The Company/Website does not guarantee that the functions and
            services contained in the Website will be uninterrupted or
            error-free, or that the Website or its server will be free of
            viruses or other harmful components, and the User hereby expressly
            accepts any and all associated risks involved with the User's use of
            the Website.
          </li>

          <li>
            It is further agreed to by the Parties that the contents of this
            Section shall survive even after the termination or expiry of the
            Terms and/or Policy.
          </li>
        </ul>

        <ul>
          <Heading
            text="14. SUBMISSIONS"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />

          <p>
            Any comments, ideas, suggestions, initiation, or any other content
            contributed by the User to the Company or this Website will be
            deemed to include a royalty-free, perpetual, irrevocable,
            nonexclusive right and license for the Company to adopt, publish,
            reproduce, disseminate, transmit, distribute, copy, use, create
            derivative works, display worldwide, or act on such content, without
            additional approval or consideration, in any media, or technology
            now known or later developed, for the full term of any rights that
            may exist in such content, and the User hereby waives any claim to
            the contrary. The User hereby represents and warrants that he/she
            owns or otherwise controls all of the rights to the content
            contributed to the Website, and that use of such content by the
            Company/Website does not infringe upon or violate the rights of any
            third party. In the event of any action initiated against the
            Company/Website by any such affected third party, the User hereby
            expressly agrees to indemnify and hold harmless the Company/Website,
            for its use of any such information provided to it by the User. The
            Company reserves its right to defend itself in any such legal
            disputes that may arise, and recover the costs incurred in such
            proceedings from the User.
          </p>
        </ul>

        <ul>
          <Heading
            text="15. SUBMISSIONS"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />
          <p>
            It is expressly agreed to by the Parties hereto that the formation,
            interpretation and performance of these Terms and any disputes
            arising here from will be resolved through a two-step Alternate
            Dispute Resolution ("ADR") mechanism. It is further agreed to by the
            Parties that the contents of this Section shall survive even after
            the termination or expiry of the Terms and/or Policy.
          </p>

          <li>
            Mediation: In case of any dispute between the parties, the Parties
            will attempt to resolve the same amicably amongst themselves, to the
            mutual satisfaction of both Parties. In the event that the Parties
            are unable to reach such an amicable solution within thirty (30)
            days of one Party communicating the existence of a dispute to the
            other Party, the dispute will be resolved by arbitration, as
            detailed herein below;
          </li>

          <li>
            Arbitration: In the event that the Parties are unable to amicably
            resolve a dispute by mediation, said dispute will be referred to
            arbitration by a sole arbitrator to be appointed by the Company, and
            the award passed by such sole arbitrator will be valid and binding
            on both Parties. The Parties shall bear their own costs for the
            proceedings, although the sole arbitrator may, in his/her sole
            discretion, direct either Party to bear the entire cost of the
            proceedings. The arbitration shall be conducted in English, and the
            seat of Arbitration shall be the city of New Delhi, India.
          </li>

          <p>
            The Parties expressly agree that the Terms, Policy and any other
            agreements entered into between the Parties are governed by the
            laws, rules and regulations of India, and that the Courts at New
            Delhi shall have exclusive jurisdiction over any disputes arising
            between the Parties.
          </p>
        </ul>

        <ul>
          <Heading
            text="16. NOTICES"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />
          <p>
            Any and all communication relating to any dispute or grievance
            experienced by the User may be communicated to the Company by the
            User reducing the same to writing, and sending the same to the
            registered office of the Company by Registered Post Acknowledgement
            Due / Speed Post Acknowledgement Due ( RPAD / SPAD).
          </p>
        </ul>

        <ul>
          <Heading
            text="16. NOTICES"
            fontSize="1.25rem"
            className="termsConditionHeading"
          />

          <li>
            Entire Agreement: These Terms, read with the Policy form the
            complete and final contract between the User and the Company with
            respect to the subject matter hereof and supersedes all other
            communications, representations and agreements (whether oral,
            written or otherwise) relating thereto;
          </li>

          <li>
            Waiver: The failure of either Party at any time to require
            performance of any provision of these Terms shall in no manner
            affect such Party's right at a later time to enforce the same. No
            waiver by either Party of any breach of these Terms, whether by
            conduct or otherwise, in any one or more instances, shall be deemed
            to be or construed as a further or continuing waiver of any such
            breach, or a waiver of any other breach of these Terms.
          </li>

          <li>
            Severability: If any provision/clause of these Terms is held to be
            invalid, illegal or unenforceable by any court or authority of
            competent jurisdiction, the validity, legality and enforceability of
            the remaining provisions/clauses of these Terms shall in no way be
            affected or impaired thereby, and each such provision/clause of
            these Terms shall be valid and enforceable to the fullest extent
            permitted by law. In such case, these Terms shall be reformed to the
            minimum extent necessary to correct any invalidity, illegality or
            unenforceability, while preserving to the maximum extent the
            original rights, intentions and commercial expectations of the
            Parties hereto, as expressed herein.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default TermsAndConditions;
