import { IoSearchOutline } from "react-icons/io5";
import { useEffect, useState } from "react";

import useApiFetcher from "../../../hooks/useApiFetcher";
import { propertyParams } from "../../../helpers/params";
import { getFullUrl } from "../../../assets/constants/apiUrls";

const SearchSuggestionBar = ({ getSearchedValue, className, navigate: cb }) => {
  const url = getFullUrl("/api/projects?populate=create_city");

  // const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleInput = (event) => {
    setValue(event.target.value);
    if (getSearchedValue) {
      getSearchedValue({ searchByValue: event.target.value });
    }
  };

  const handleOpenSearchSuggestion = () => setIsSuggestionOpen(true);

  const handleCloseSearchSuggestion = () =>
    setTimeout(() => setIsSuggestionOpen(false), 300);

  const [searchSuggestions, isLoading, error] = useApiFetcher(url);

  useEffect(() => {
    if (value) {
      const filterData = searchSuggestions?.filter((item) => {
        const {
          Project_Name,
          Project_Configuration = "",
          Address = "",
        } = propertyParams(item);

        return (
          Project_Name?.toLowerCase()?.includes(value.toLowerCase()) ||
          Project_Configuration?.toLowerCase()?.includes(value.toLowerCase()) ||
          Address?.toLowerCase()?.includes(value.toLowerCase())
        );
      });
      setFilteredSuggestions(filterData);
    } else {
      setFilteredSuggestions([]);
    }
  }, [value, searchSuggestions]);

  if (error) return <div>{error.message}</div>;
  if (isLoading || !searchSuggestions)
    return <div className="w-100 center-item">Search Bar</div>;

  const handleSelectValue = (value, keyName) => {
    if (getSearchedValue) {
      getSearchedValue({ [keyName]: value });
    }
    setValue(value);
  };

  return (
    <div className={`input-width d-flex ${className}`}>
      <div className="searchBarLogo fw-bold">
        <IoSearchOutline />
      </div>
      <input
        value={value}
        placeholder="Search for locality, Property or Project"
        onChange={handleInput}
        onClick={handleOpenSearchSuggestion}
        onBlur={handleCloseSearchSuggestion}
        onFocus={handleOpenSearchSuggestion}
      />

      {isSuggestionOpen && filteredSuggestions.length > 0 && (
        <div className="inputSuggestions">
          {filteredSuggestions?.map((item, idx) => {
            const {
              Project_Name,
              Project_Configuration,
              Address,
              Slug_Url,
              cityName,
            } = propertyParams(item);

            return (
              <div
                key={idx}
                onClick={() => cb && cb(`/${cityName}/${Slug_Url}`)}
              >
                <div>
                  {Project_Name && (
                    <li
                      onClick={() =>
                        handleSelectValue(
                          Project_Name,
                          "Project_Name",
                          Slug_Url
                        )
                      }
                    >
                      {Project_Name}
                    </li>
                  )}
                  {Project_Configuration && (
                    <li
                      onClick={() =>
                        handleSelectValue(
                          Project_Configuration,
                          "Project_Configuration"
                        )
                      }
                    >
                      {Project_Configuration}
                    </li>
                  )}
                  {Address && (
                    <li onClick={() => handleSelectValue(Address, "Address")}>
                      {Address}
                    </li>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default SearchSuggestionBar;
