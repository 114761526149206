import "./skeleton.css";

const SkeletonBox = ({ className, children, ...props }) => {
  return (
    <div className={`${className} wave`} {...props}>
      {children}
    </div>
  );
};

export default SkeletonBox;
