import Header from "../../globalComponents/header";
import Heading from "../../globalComponents/molecules/Heading";
import HeaderMob from "./../../mobileComponents/HeaderMob";
import AdvancedSearchBar from "../../advancedSearchBar/AdvancedSearchBar";
import TemplateCard from "./TemplateCard";

import useDeviceSize from "../../../hooks/useDeviceSize";
import "./newTemplateSlider.css";

const NewTemplateSlider = ({ searchBar, text, style, height, textImg }) => {
  const isMobile = useDeviceSize();
  let newStyle = style;

  let heightIs = height ? height : isMobile ? "360px" : "100vh";

  return (
    <section className="container-fluid p-0">
      <div
        className="video-container"
        style={{ height: heightIs, ...newStyle }}
      >
        {/* <video
            className="border border-danger h-auto"
            autoPlay
            loop
            muted
            style={{ height: "100vh" }}
          >
            <source src={VIDEO_URL} type="video/mp4" />
          </video> */}

        <div className="templateContainer">
          <TemplateCard imgHeight={heightIs} />
        </div>

        <div className="content-on-top">
          <div className="header-container">
            {isMobile ? <HeaderMob /> : <Header />}
          </div>

          <div className="searchBar-container">
            {text && text !== "" ? (
              <Heading
                tag="h1"
                text={text}
                fontSize="2.5rem"
                fontWeight="900"
                color="#fff"
                className="text-center text-capitalize fs-1"
              />
            ) : null}

            {searchBar === "enabled" ? (
              <div className="d-flex justify-content-center">
                <AdvancedSearchBar />
              </div>
            ) : null}

            <p className="text-white text-center fs-lg">
              The Most Followed Real Estate Consultant in India
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewTemplateSlider;
