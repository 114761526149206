import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";

const useDownload = () => {
  const [isDownloading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const downloadPdf = async (url, message) => {
    try {
      const response = await axios.get(url, { responseType: "blob" }); // Fetch as binary
      const blob = new Blob([response.data], { type: "application/pdf" });
      const downloadTemporaryUrl = URL.createObjectURL(blob);

      const anchorTag = document.createElement("a");
      anchorTag.href = downloadTemporaryUrl;
      anchorTag.download = url.split("/").pop();
      anchorTag.click();
      URL.revokeObjectURL(downloadTemporaryUrl); // Clean link up after download from the browser
      setIsLoading(false);
      toast(message);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setError("Failed to download PDF.");
    }
  };

  return { downloadPdf, isDownloading, error, setError, setIsLoading };
};

export default useDownload;
