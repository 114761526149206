import { useDispatch, useSelector } from "react-redux";
import {
  addItemToWishlist,
  removeItemFromWishlist,
} from "../../../Redux/slice/wishListSlice";
import { useState, useEffect } from "react";

import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";

import "./wishListBtn.css";

const WishListBtn = ({ cardData }) => {
  const [isBtnActive, setIsBtnActive] = useState(false);
  const { Project_Name, Address, img, cityName, propertyId } = cardData;
  const wishLists = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsBtnActive(wishLists.projectsId[`${propertyId}`]);
  }, [wishLists, propertyId]);

  const handleWishList = (propertyId) => {
    if (!isBtnActive) {
      dispatch(
        addItemToWishlist({
          id: propertyId,
          img: img,
          price: `${cardData.minPrice} - ${cardData.maxPrice} `,
          address: Address,
          projectName: Project_Name,
          city: cityName,
        })
      );
    } else {
      dispatch(removeItemFromWishlist(propertyId));
      // toast.error("Property Removed From WishList");
    }
    setIsBtnActive(!isBtnActive);
  };
  return (
    <div
      className=""
      onClick={(e) => {
        e.preventDefault();
        handleWishList(propertyId);
      }}
    >
      {wishLists.projectsId[`${propertyId}`] ? (
        <FaHeart className="wishListBtnColorStyle" />
      ) : (
        <FaRegHeart className="wishListBtnColorStyle" />
      )}
    </div>
  );
};

export default WishListBtn;
