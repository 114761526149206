import { postMethod } from "../../services";
import { EMPTY_OBJECT } from "./index";

const ZOHO_API_URL =
  "https://bold-approval-c005df0fb8.strapiapp.com/api/zoho-form";
// "http://localhost:1337/api/zoho-form";
// const OWNER_ID = 3664837000038157001; // replace key

export const submissionOnZoho = async (formData = EMPTY_OBJECT) => {
  const zohoUpdatedParams = getZohoParams(formData);
  // const params = new URLSearchParams(zohoUpdatedParams);
  // const URL = `${ZOHO_API_URL}?${params.toString()}`;
  // const url = checkFunction(zohoUpdatedParams);
  // console.log(url);

  const response = await postMethod(
    ZOHO_API_URL,
    zohoUpdatedParams,
    "Form Successfully Submitted"
  );

  return response;
};

export const getZohoParams = ({
  formName = "Project_Name",
  countryCode = "",
  name = "",
  lastName = "",
  phone = null,
  email = "",
  budget = null,
  propertyType = null,
  location = null,
  message = null,
  cityName = null,
}) => {
  // Query= Messages - Temp3(Noida) & Budget(budget value).
  // Propert Type- Property Type.
  // Temp3-Project Name.

  const displayMessage = (message, formName, budget) => {
    const msgFormat = `${message || ""} - ${formName} %26 ${budget || ""}`;

    // console.log(encodeURIComponent(msgFormat));

    return encodeURIComponent(msgFormat);
    // return propertyType && message
    //   ? `${propertyType} : ${message}`
    //   : propertyType || message || null;
  };

  return {
    Contact_ID: "123",
    Last_Name: name + lastName,
    Email: email,
    Mobile: `${countryCode} ${phone}`,
    Query: displayMessage(message, formName, budget), // propertyType
    Locationn: cityName || location,
    Budget: budget,
    Lead_Source1: "Website lead",
    Lead_Type: "ONLINE", //whatsapp/ PhoneCall Type
    Rating: "Hot",
    Lead_Priority: "Fresh",
    Stage: "Fresh",
    Temp_Field_3: formName, // form Name
    Property_Type: propertyType,
  };
};

// export function checkFunction(data) {
//   const {
//     Contact_ID = "123",
//     Last_Name,
//     Email,
//     Mobile,
//     Query, // propertyType
//     Locationn,
//     Budget,
//     Lead_Source1,
//     Lead_Type, //whatsapp/ PhoneCall Type
//     Rating,
//     Lead_Priority,
//     Stage,
//     Temp_Field_3, // form Name
//     // Property_Type,
//   } = data;
//   const newUrl = `https://www.zohoapis.com/crm/v2/functions/property_wala/actions/execute?auth_type=apikey&zapikey=1003.1e4337563bdfd4d5d09450bda8cfcf36.6c69bb448b86aa64654ef89ca2018684&Contact_ID=${Contact_ID}&Last_Name=${Last_Name}&Email=${Email}&Mobile=${Mobile}&Query=${Query}&Locationn=${Locationn}&Budget=${Budget}&Lead_Source1=${Lead_Source1}&Lead_Type=${Lead_Type}&Rating=${Rating}&Lead_Priority=${Lead_Priority}&Stage=${Stage}&Temp_Field_3=${Temp_Field_3}&Owner=3664837000038157001`;

//   return newUrl;
// }
