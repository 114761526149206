import { EMPTY_ARRAY } from "../../../../../assets/constants";

const extractNearAreasAttributes = (nearAreas = EMPTY_ARRAY) => {
  return nearAreas.map((area) => ({
    id: area.id,
    area: area.Area_Name,
  }));
};

export { extractNearAreasAttributes };
