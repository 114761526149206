import { EMPTY_ARRAY, EMPTY_OBJECT } from "../assets/constants";
import { formatIndianCurrency } from "./helper";

export const getDeviceImageUrlParams = (image) => {
  return image?.attributes?.formats?.small?.url;
};

export const propertyParams = (property) => {
  const {
    Project_Name = "",
    Min_Price = "",
    Max_Price = "",
    Project_Configuration,
    Address = "",
    Project_Disclaimer,
    Image = EMPTY_ARRAY,
    Priority,
    Slug_Url = "",
    Meta_Title = "",
    Meta_Description = "",
    Meta_Link = "",
    create_city = "",
    category = "",
    Description = "",
    RegNo = "",
    // sub_category=""
  } = property?.attributes || EMPTY_OBJECT;

  let img;
  let mobileImg;
  let altTag;
  let imgTitle;

  if (Image?.data) {
    img = Image?.data[0]?.attributes?.url || "";
    mobileImg = getDeviceImageUrlParams(Image?.data[0]) || "";
    altTag = Image?.data[0]?.attributes?.alternativeText;
    imgTitle = Image?.data[0]?.attributes?.title || "project card img title";
  }

  // const [projectName,address,projectDisclaimer] = []

  const cityName = create_city?.data?.attributes?.City_Name;
  const propertyId = property?.id;

  const configurations =
    (Project_Configuration && Project_Configuration.split(" | ")) || "";

  const maxPrice = formatIndianCurrency(Max_Price) || "";
  const minPrice = formatIndianCurrency(Min_Price) || "";
  const buildingType = category?.data?.attributes?.Title || "";

  return {
    Project_Name,
    Project_Configuration,
    Address,
    Project_Disclaimer,
    description: Description,
    Image,
    configurations,
    propertyId,
    Min_Price,
    Max_Price,
    maxPrice,
    minPrice,
    Priority,
    Slug_Url,
    Meta_Title,
    Meta_Description,
    Meta_Link,
    img,
    mobileImg,
    altTag,
    imgTitle,
    cityName,
    buildingType,
    RegNo,
  };
};

export const blogsCardParams = (blogsData) => {
  const {
    Title = "",
    Pubish_Date,
    Meta_Title = "",
    Meta_Description = EMPTY_ARRAY,
    Meta_Link = "",
    Slug_Url = "",
    Image,
    blog_categories = "",
  } = blogsData || EMPTY_OBJECT;

  const img = Image?.attributes?.url || "";
  const mobileImg = getDeviceImageUrlParams(Image) || "";
  const altTag =
    Image?.attributes?.alternativeText || "wealth clinic blogs images";
  const imgTitle =
    Image?.attributes?.alternativeText?.name || "wealth clinic image title";

  return {
    Title,
    Pubish_Date,
    Meta_Title,
    Meta_Description,
    Meta_Link,
    Slug_Url,
    Image,
    blog_categories,
    img,
    mobileImg,
    altTag,
    imgTitle,
  };
};

export const ourTeamParams = (teamMemberData) => {
  const {
    Name = "",
    Designation = "",
    Profile_Image,
    Description,
  } = teamMemberData?.attributes || EMPTY_OBJECT;
  const img = Profile_Image?.data?.attributes?.url || "";
  const mobileImg = getDeviceImageUrlParams(Profile_Image?.data) || "";
  const altTag =
    Profile_Image?.data?.attributes?.alternativeText ||
    "Wealth clinic team member cards";
  const imgTitle =
    Profile_Image?.data?.attributes?.title || "Wealth clinic team member cards";

  return {
    Name,
    Designation,
    Profile_Image,
    Description,
    img,
    mobileImg,
    altTag,
    imgTitle,
  };
};
