import { EMPTY_ARRAY, EMPTY_OBJECT } from "../assets/constants";

const extractImageAttributes = (imageDataArray = EMPTY_ARRAY) => {
  return imageDataArray?.map((item = EMPTY_OBJECT) => {
    const ID = item?.id || "";
    const {
      url: imgUrl = "",
      alternativeText = "",
      caption: imgTitle = "",
    } = item?.attributes || EMPTY_OBJECT;

    return {
      id: ID,
      img: imgUrl,
      alt: alternativeText,
      title: imgTitle,
    };
  });
};

export { extractImageAttributes };
