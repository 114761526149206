import { useState } from "react";
import { API_URL, getFullUrl } from "../../../../../assets/constants/apiUrls";
import useApiFetcher from "../../../../../hooks/useApiFetcher";
import NewTeamMemberCard from "./NewTeamMemberCard";

const NewTeamMembersContainer = () => {
  const [activeIndex, setActiveIndex] = useState(1); // Set the second card (index 1) as active initially
  const url = getFullUrl(`${API_URL.TEAMS}?sort=id&populate=*`);
  const [teams, error, isLoading] = useApiFetcher(url);

  if (error) return <div>{error.message}</div>;
  if (isLoading) return null;

  const CARD_OPENER_DATA = teams;

  return (
    <section className="mb-5">
      <header>
        <h2 className="p-3 text-center" style={{ color: "#f37535" }}>
          Our Team
        </h2>
      </header>

      <div className="grid-container">
        {CARD_OPENER_DATA?.map((item, idx) => (
          <NewTeamMemberCard
            key={idx}
            data={item}
            index={idx}
            isActive={idx === activeIndex}
            setActiveIndex={setActiveIndex}
          />
        ))}
      </div>
    </section>
  );
};

export default NewTeamMembersContainer;
