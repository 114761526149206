export const AWARD_TAB = {
  // EMPLOYEE: "Employee Achievements",
  // EMPLOYEE: "Associate Awards",
  // COMPANY: "Company Achievements",
  // COMPANY: "Associate Achievements",
  COMPANY: "Company Achievements",
  EMPLOYEE: "Associate Achievements",
  AWARDS_AND_RECOGNITIONS: "Awards and Recognitions",
};

export const AWARD_TABS = [AWARD_TAB.COMPANY, AWARD_TAB.EMPLOYEE];
