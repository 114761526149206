import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";

import ButtonDarkBlue from "../molecules/ButtonDarkBlue";

// import { postMethod } from "../../../services/api.service";
// import { getFullUrlLocal } from "../../../assets/constants/apiUrls";
import InputCustom from "../molecules/InputCustom";
import { submissionOnZoho } from "../../../assets/constants/zoho";
import "react-toastify/dist/ReactToastify.css";

const initialRegisterFields = {
  formName: "User Registration Form",
  name: "",
  phone: "",
  email: "",
  password: "",
  confirmPassword: "",
};

// const isFieldEmpty = (newObj) => {
//   return (
//     newObj.name.trim() &&
//     newObj.phone.trim() &&
//     newObj.email.trim() &&
//     newObj.password.trim() &&
//     newObj.confirmPassword.trim()
//   );
// };

const RegisterForm = () => {
  // const url = getFullUrlLocal("/api/auth/local/register");
  const [formValue, setFormValue] = useState(initialRegisterFields);

  const { name, phone, email, password, confirmPassword } = formValue;

  const handleRegisterForm = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm(formValue);
    if (Object.keys(validationErrors).length > 0) {
      toast.error("Please fill the form correctly");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    // const registerToken = await
    // postMethod(url, {
    //   username: name,
    //   email: email,
    //   password: password,
    // });
    try {
      await submissionOnZoho(formValue);
      // toast.success("Form submitted successfully to Zoho!");
      setFormValue(initialRegisterFields);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleInputChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div>
      <form onSubmit={handleRegisterForm}>
        <InputCustom
          placeholder="Enter Name"
          className="border px-3 rounded-2"
          value={name}
          name="name"
          onChange={handleInputChange}
        />
        <InputCustom
          placeholder="Enter Phone (optional)"
          className="border px-3 rounded-2"
          value={phone}
          name="phone"
          onChange={handleInputChange}
          required
        />
        <InputCustom
          placeholder="Enter Email"
          className="border px-3 rounded-2 w-100"
          value={email}
          name="email"
          onChange={handleInputChange}
          required
        />
        <InputCustom
          placeholder="Enter Password"
          className="border px-3 rounded-2"
          value={password}
          name="password"
          type="password"
          onChange={handleInputChange}
          required
        />
        <InputCustom
          placeholder="Enter Confirm Password"
          className="border px-3 rounded-2"
          value={confirmPassword}
          name="confirmPassword"
          type="password"
          onChange={handleInputChange}
          required
        />
        <ButtonDarkBlue name="Register" className="rounded-2" type="submit" />
      </form>
      <ToastContainer />
    </div>
  );
};

export default RegisterForm;

const validateForm = (formValue) => {
  const newErrors = {};

  if (!formValue.name.trim()) newErrors.name = "Name is required.";
  if (!formValue.email.trim()) {
    newErrors.email = "Email is required.";
  } else if (!/\S+@\S+\.\S+/.test(formValue.email)) {
    newErrors.email = "Email is invalid.";
  }
  if (!formValue.phone.trim()) {
    newErrors.phone = "Phone Number is required.";
  } else if (!/^\d{10}$/.test(formValue.phone)) {
    newErrors.phone = "Phone Number must be 10 digits.";
  }
  if (!formValue.password.trim()) newErrors.password = "Password is required.";
  if (!formValue.confirmPassword.trim())
    newErrors.confirmPassword = "Confirm Password is required.";
  if (formValue.password !== formValue.confirmPassword)
    newErrors.confirmPassword = "Passwords do not match.";

  return newErrors;
};
