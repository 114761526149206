import { Link } from "react-router-dom";
import { Checkbox } from "@mui/material";
import { useState } from "react";

import ButtonDarkBlue from "../../../../globalComponents/molecules/ButtonDarkBlue";
import CallSvg from "../../../../globalComponents/molecules/CallSvg";
import Heading from "../../../../globalComponents/molecules/Heading";
import InputCustom from "../../../../globalComponents/molecules/InputCustom";
import SelectOption from "../../../../globalComponents/molecules/SelectOption";
import ThankYouMessage from "../../../../globalComponents/ThankYouMessage";

import { submissionOnZoho } from "../../../../../assets/constants/zoho";
// import { ALL_CITIES } from "../../../../../assets/constants/cities";
import { FORM_FOOTER_IMG } from "../../assets/constant";
import URL from "../../../../../assets/constants/url";
import { FORM_NAMES } from "../../../../../assets/constants/formNames";
import { PROCESS_MESSAGE } from "../../../../../assets/constants/messages";
import "./style.css";

const COUNTRY_CODE = ["+01", "+41", "+61", "+91"];

const PROPERTY_DETAILS_PAGE_FORM = {
  countryCode: "+91",
  name: "",
  email: "",
  phone: "",
  // cityName: "",
};
const ProjectContactForm = ({ projectData, className }) => {
  const [formValue, setFormValue] = useState(PROPERTY_DETAILS_PAGE_FORM);
  const [errors, setErrors] = useState({});
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(false);

  const { name, email, phone, countryCode } = formValue;

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formValue);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      setIsBtnDisable(true);
      // toast("Form Submitted Successfully");
      await submissionOnZoho(formValue);
      setIsBtnDisable(false);
      setIsSuccessfullySubmitted(true);
      setFormValue(PROPERTY_DETAILS_PAGE_FORM);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({
      formName:
        projectData?.Project_Name || FORM_NAMES?.PROPERTY_DETAILS_FORM_NAME,
      ...formValue,
      [name]: value,
    });
  };

  const handleThankYouComplete = () => setIsSuccessfullySubmitted(false);

  return (
    <div
      className={`contactExpertForm col-md-12 col-sm-12 col-xs-12`}
      style={{ position: "relative" }}
    >
      <div className="d-flex my-2">
        <div>
          <CallSvg
            style={{
              width: "30px",
              height: "30px",
              transform: "rotate(-45deg)",
              marginRight: "0.25rem",
              border: "1px solid orange",
              borderRadius: "50%",
              padding: "0.25rem",
              color: "var(--global-logo-color)",
            }}
          />
        </div>

        <Heading
          text={"Contact Our Real Estate Experts"}
          fontSize={"1rem"}
          showLine="5"
          color="111"
          fontWeight="600"
          style={{ lineHeight: "1.5rem" }}
        />
      </div>

      <form
        onSubmit={handleSubmitForm}
        className={`${className} property-contact-form  rounded-1 p-2`}
      >
        <InputCustom
          placeholder="Enter Name"
          className="px-3 rounded-2"
          name="name"
          value={name}
          onChange={handleChange}
        />
        {errors.name && <div className="alertMsg">{errors.name}</div>}

        <InputCustom
          placeholder="Enter Email"
          className="px-3 rounded-1"
          name="email"
          value={email}
          onChange={handleChange}
        />
        {errors.email && <div className="alertMsg">{errors.email}</div>}

        <div className="col-lg-12 d-flex justify-content-between">
          <SelectOption
            data={COUNTRY_CODE}
            className="px-2 rounded-1 w-100"
            selectText="+91"
            name="countryCode"
            value={countryCode}
            onChange={handleChange}
          />
          <InputCustom
            placeholder="Phone Number"
            className="px-4 rounded-1 w-100"
            name="phone"
            value={phone}
            onChange={handleChange}
          />
        </div>
        {errors.phone && <div className="alertMsg">{errors.phone}</div>}

        {/* <SelectOption
          data={ALL_CITIES}
          className="rounded-1"
          selectText="Select City"
          name="cityName"
          value={cityName}
          onChange={handleChange}
        />
        {errors.cityName && (
          <div className="alertMsg">{errors.cityName}</div>
        )} */}

        <div
          className="lineClamp1 center-item"
          title="i accept terms of use & privacy policy"
          style={{ fontSize: ".75rem" }}
        >
          <Checkbox
            defaultChecked
            size="small"
            style={{ color: "#ef750f" }}
            disabled
          />
          I accept terms of use &{" "}
          <Link className="del-underLine" to={URL.PRIVACY_POLICY}>
            privacy policy
          </Link>
        </div>

        <ButtonDarkBlue
          name={
            isBtnDisable
              ? PROCESS_MESSAGE.FORM_SUBMISSION_IN_PROGRESS
              : "Contact Now"
          }
          className="px-3 rounded-1"
          disabled={isBtnDisable}
        />
      </form>
      <FormDownSection />
      {isSuccessfullySubmitted && (
        <ThankYouMessage onComplete={handleThankYouComplete} />
      )}
    </div>
  );
};

export default ProjectContactForm;

export const FormDownSection = () => {
  return (
    <div className="container">
      <div className="fw-bolder mb-2 text-center">
        Why Buy Through
        <span className="theme-text"> Wealth Clinic?</span>
      </div>
      <div className="row mx-0 formFooter">
        {FORM_FOOTER_IMG?.map((ele, idx) => {
          return (
            <div key={idx} className="col-4 p-1">
              <div className="formFooterImgBox">
                <img alt={ele?.title} src={ele?.img} />
                <div className="formFooterText text-capitalize">
                  {ele?.title}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const validateForm = (formValue) => {
  const newErrors = {};
  const { name, email, phone } = formValue;
  if (!name.trim()) newErrors.name = "Full Name is required.";
  if (!email.trim()) {
    newErrors.email = "Email is required.";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    newErrors.email = "Email is invalid.";
  }
  if (!phone.trim()) {
    newErrors.phone = "Phone Number is required.";
  } else if (!/^\d{10}$/.test(phone)) {
    newErrors.phone = "Phone Number must be 10 digits.";
  }
  // if (!cityName) newErrors.cityName = "City Name is required.";

  return newErrors;
};
