import React, { useCallback, useState } from "react";
import useApiFetcher from "../../../hooks/useApiFetcher";

import AwardCard from "./AwardCard";
import TextBg from "../../globalComponents/molecules/TextBg";

import { API_URL, getFullUrl } from "../../../assets/constants/apiUrls";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../assets/constants";
import { AWARD_TAB, AWARD_TABS } from "./constant";
import "./AwardCard/awardCard.css";

const Awards = () => {
  const companyUrl = getFullUrl(`${API_URL.AWARDS}${API_URL.POPULATE}`);
  const employeeUrl = getFullUrl(
    `${API_URL.EMPLOYEE_AWARDS}${API_URL.POPULATE}`
  );

  const [activeTabId, setActiveTabId] = useState(AWARD_TAB.COMPANY);
  const [initialYear, setInitialYear] = useState("2023");

  const [awards, companyError, companyIsLoading] = useApiFetcher(companyUrl);

  const [employeeAwards, employeeError, employeeIsLoading] =
    useApiFetcher(employeeUrl);

  const handleActiveTabBtn = useCallback((tabValue) => {
    setActiveTabId(tabValue);
  }, []);

  if (companyError)
    <div>Error loading company awards: {companyError.message}</div>;
  if (employeeError)
    <div>Error loading employee awards: {employeeError.message}</div>;
  if (companyIsLoading || employeeIsLoading) <div>Loading awards...</div>;

  const isCompanyTabActive = activeTabId === AWARD_TAB.COMPANY ? true : false;

  const awardsInfo = extractAwardsDetails(
    isCompanyTabActive ? awards : employeeAwards
  );
  const mappedAwards = mappingAwardsByYear(awardsInfo);
  // const employeeAwardsInfo = extractAwardsDetails(employeeAwards);
  // const mappedEmployeeAwards = mappingAwardsByYear(companyAwardsInfo);
  const sortedAwardsYears = Object.keys(mappedAwards).sort((a, b) => b - a);
  const displayedAwardsImagesByYear = mappedAwards[initialYear];
  // const mappedEmployeeAwardsByYear = mappedEmployeeAwards[initialYear];
  // const displayedAwardsImagesByYear = mappedAwardsByYear;

  return (
    <div className="container-fluid p-2">
      <div className="container">
        <div className="awardsTabsContainer">
          {AWARD_TABS?.map((tabValue, idx) => (
            <TabButton
              key={idx}
              tabValue={tabValue}
              isTabActive={tabValue === activeTabId}
              onClick={handleActiveTabBtn}
            />
          ))}
        </div>

        <div className="row d-flex justify-content-center">
          <TextBg text={activeTabId} fontSize="4.5rem" fontWeight="800" />
        </div>

        <div className="row awardCardContainer justify-content-center">
          {/* activeTabId === AWARD_TAB.COMPANY && ( */}
          {
            <div className="col-md-2 py-2 yearSideBarContainer">
              {sortedAwardsYears?.map((year, idx) => (
                <YearSideBar
                  key={idx}
                  year={year}
                  onClick={() => setInitialYear(year)}
                  activeYearBtn={year === initialYear}
                />
              ))}
            </div>
          }
          <div
            className={`row ${
              activeTabId === AWARD_TAB.COMPANY ? "col-md-10" : "col-md-10"
            } `}
          >
            {displayedAwardsImagesByYear?.map((awardsInfo, idx) => (
              <AwardCard key={idx} awardData={awardsInfo} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;

export const TabButton = React.memo(({ tabValue, isTabActive, onClick }) => {
  const getTabButtonClass = () => (isTabActive ? "tabBtn_active" : "");
  return (
    <div className="col-md-4">
      <div
        className={`tabBtn ${getTabButtonClass()}`}
        onClick={() => onClick(tabValue)}
      >
        <span>{tabValue}</span>
      </div>
    </div>
  );
});

export const YearSideBar = ({ year, activeYearBtn, ...props }) => {
  return (
    <>
      <div
        className={`yearBtn ${activeYearBtn ? "activeYearBtn" : ""}`}
        {...props}
      >
        {year}
      </div>
    </>
  );
};

const extractAwardsDetails = (awards) =>
  awards?.flatMap((award) => {
    const { Award_Title, Award_Date, Description, Award_Image } =
      award?.attributes || EMPTY_OBJECT;

    return {
      img: Award_Image?.data?.attributes?.url,
      title: Award_Title,
      date: Award_Date,
      description: Description,
    };
  }) || EMPTY_ARRAY;

const mappingAwardsByYear = function (data) {
  return data?.reduce((acc, nextAwardDetails) => {
    // const { Award_Title, Description, Award_Image } =
    //   nextAwardDetails?.attributes;
    // const img = Award_Image?.data?.attributes?.url;

    const { img, title, date, description } = nextAwardDetails;

    const newYear = new Date(date).getFullYear();
    if (!acc[newYear]) {
      acc[newYear] = [];
    }

    acc[newYear].push({
      title,
      description,
      img,
    });

    return acc;
  }, {});
};

// const extractAwardImageUrl = (awards) =>
//   awards
//     ?.map((award) => award?.attributes?.Award_Image?.data)
//     .map((image) => `${image.attributes?.url}`) || EMPTY_ARRAY;

// export const TabButton = ({ tabValue }) => {
//   const [activeTabId, setActiveTabId] = useState(null);
//   const [isTabActive, setIsTabActive] = useState("");

//   const handleTabBtnChange = (tabValue) => {
//     const tabBtnStyle = (tabValue) => {
//       return tabValue === activeTabId ? "tabBtn_active" : "";
//     };
//     console.log("clicked");
//     setActiveTabId(tabValue);
//     setIsTabActive(tabBtnStyle(tabValue));
//   };

//   console.log("======", isTabActive, "====", activeTabId);
//   return (
//     <div className="col-md-4">
//       <div
//         className={`tabBtn ${isTabActive}`}
//         onClick={() => handleTabBtnChange(tabValue)}
//       >
//         <span>{tabValue}</span>
//       </div>
//     </div>
//   );
// };
