import { useNavigate } from "react-router-dom";
import { PiCurrencyInrFill } from "react-icons/pi";
import { ImLocation2 } from "react-icons/im";

import { propertyParams } from "../../../../../helpers/params";
import { extractImageAttributes } from "../../helper/extractAttributes";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../../assets/constants";
import "./similarProjectCard.css";

const SimilarProjectCard = ({ similarProjectData = EMPTY_OBJECT }) => {
  const {
    Project_Name: projectName,
    Image,
    minPrice = "",
    maxPrice = "",
    Address: address = "",
    cityName,
    Slug_Url,
  } = propertyParams(similarProjectData);

  const navigate = useNavigate();

  const imageData = extractImageAttributes(Image?.data) || EMPTY_ARRAY;
  const { alt, title, img } = imageData[0] || EMPTY_OBJECT;

  return (
    <div className="similarProjectCardBox">
      <img
        className="img-fluid w-100 "
        src={
          img ||
          "https://bold-approval-c005df0fb8.media.strapiapp.com/small_1_f5bb07ce2f.jpg"
        }
        alt={alt}
        title={title}
        loading="lazy"
      />
      <div className="similarProjectCardDetailsBox">
        <div
          className="lineClamp1 fw-semibold text-capitalize"
          title="projectName"
        >
          {projectName}
        </div>

        <div
          className="address similarProjectAddress vertical-center"
          title="projectName"
        >
          <div>
            <ImLocation2 className="theme-text fs-5" />
          </div>
          {""}
          <span className="lineClamp1 px-1">{address}</span>
        </div>

        <div className="price">
          <div className="d-flex">
            <div className="center-item">
              <PiCurrencyInrFill className="theme-text fs-5" />
            </div>
            <div className="px-1 fw-semibold">
              {minPrice} - {maxPrice}{" "}
            </div>
          </div>

          <button
            className="contactBtn"
            onClick={() => navigate(`/${cityName}/${Slug_Url}`)}
          >
            View Detail
          </button>
        </div>
      </div>
    </div>
  );
};

export default SimilarProjectCard;
