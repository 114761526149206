import { useEffect } from "react";

import useDeviceSize from "../../../../../hooks/useDeviceSize";
import { ourTeamParams } from "../../../../../helpers/params";
import "./newTeamMemberCard.css";

const NewTeamMemberCard = ({ data, index, isActive, setActiveIndex }) => {
  const isMobile = useDeviceSize();
  const { Name, Designation, img, mobileImg, altTag, imgTitle } =
    ourTeamParams(data);

  useEffect(() => {
    if (isActive) {
      setActiveIndex(index);
    }
  }, [isActive, index, setActiveIndex]);

  const handleMouseOver = () => {
    setActiveIndex(index);
  };

  return (
    <div
      className={`grid-item ${isActive ? "active" : ""}`}
      onMouseOver={handleMouseOver}
    >
      <div className="grid-item-content">
        <img
          alt={altTag}
          title={imgTitle}
          src={isMobile ? mobileImg : img}
          loading="lazy"
        />
        <h3>{Name}</h3>
        <span>{Designation}</span>
      </div>
    </div>
  );
};

export default NewTeamMemberCard;

export function TeamMembersSocialIcons() {
  return (
    <div className="social-links">
      <a href="/">
        <i className="social-sec-12 fab fa-facebook"></i>
      </a>
      <a href="/twitter">
        <i className="social-sec-12 fab fa-twitter"></i>
      </a>
      <a href="/instagram.com">
        <i className="social-sec-12 fab fa-instagram"></i>
      </a>
    </div>
  );
}
