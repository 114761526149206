import React, { memo } from "react";

import CareerAccordionCard from "../CareerAccordionCard/";

const CareerAccordionCardList = memo(
  ({ filteredPositions, callBackHandleJobForm }) => {
    return (
      <>
        {filteredPositions.map((position, idx) => {
          const { Position_Name } = position?.attributes;
          return (
            // <div className="col-12">
            <CareerAccordionCard
              // OldCareerAccordionCard
              key={idx}
              index={idx}
              position={position}
              handleForm={() => callBackHandleJobForm(Position_Name)}
            />
            // </div>xx
          );
        })}
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.filteredPositions === nextProps.filteredPositions;
  }
);

export default CareerAccordionCardList;
