import { useEffect, useState } from "react";
// use to get size of Html element like can grt siz  of div / header footer cards

const useGetElementSize = (refOfElement) => {
  const [elementSize, setElementSize] = useState(0);
  useEffect(() => {
    if (refOfElement.current) {
      setElementSize(refOfElement.current.offsetHeight);
    }
  }, [refOfElement]);

  return elementSize;
};

export default useGetElementSize;
