import Slider from "react-slick";
import CustomLink from "../../CustomLink";
import SkeletonBox from "../skeleton/SkeletonBox";

// import { SLIDES } from "../../../assets/IMG";
import useApiFetcher from "../../../hooks/useApiFetcher";
import useDeviceSize from "../../../hooks/useDeviceSize";
import { getFullUrl } from "../../../assets/constants/apiUrls";
import { propertyParams } from "../../../helpers/params";
import { EMPTY_ARRAY } from "../../../assets/constants";
import "./fliperCardSlider.css";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: false,
  autoplaySpeed: 1500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const FliperCardSlider = () => {
  const url = getFullUrl(
    "/api/projects?populate[0]=Image&populate[1]=sub_category&populate[2]=create_city&populate[3]=category&pagination[limit]=10&filters[newLaunches]=true"
  );
  const [projects, error, isLoading] = useApiFetcher(url);
  if (!projects || isLoading) return <FlipCardSkeleton />;
  if (error) return <div>{error}</div>;

  const SLIDES = formatProjects(projects) || EMPTY_ARRAY;

  return (
    <div className="container x`x ">
      <Slider {...settings}>
        {SLIDES.map((slide, idx) => (
          <Flider3dCard slideData={slide} key={idx} />
        ))}
      </Slider>
    </div>
  );
};

export default FliperCardSlider;

function Flider3dCard({ slideData }) {
  const { img, type, city, name, slugUrl } = slideData;

  return (
    <CustomLink to={`/${city}/${slugUrl}`}>
      <div className="box-item">
        <div className="flip-box">
          <div
            className="flip-box-front text-center"
            style={{ backgroundImage: `url(${img})` }}
          >
            <div className="inner color-white">
              <h3 className="flip-box-header">{name}</h3>
              <p>
                {type} | {city}
              </p>
            </div>
          </div>
          <div
            className="flip-box-back text-center"
            style={{ backgroundImage: `url(${img})` }}
          >
            <div className="inner color-white">
              <h3 className="flip-box-header">{name}</h3>
              <p>
                {type} | {city}
              </p>
            </div>
          </div>
        </div>
      </div>
    </CustomLink>
  );
}

function formatProjects(projects) {
  return projects?.map((project) => {
    const { Project_Name, img, altTag, cityName, buildingType, Slug_Url } =
      propertyParams(project);
    return {
      img,
      altTag: altTag,
      name: Project_Name,
      type: buildingType,
      city: cityName,
      slugUrl: Slug_Url,
    };
  });
}

const FlipCardSkeleton = () => {
  const isMobile = useDeviceSize();
  return (
    <div className="container">
      <div className="row flipCard-skeleton center-item">
        {[...Array(isMobile ? 1 : 3)].map((_, idx) => {
          return (
            <div
              key={idx}
              className="col-md-4 col-sm-6 col-xs-6 col-lg-3 mt-lg-5  mt-lg-5 mb-xs-5"
            >
              <SkeletonBox
                style={{
                  height: `${isMobile ? "15rem" : "25rem"}`,
                }}
                className="rounded w-100"
              >
                {/* <div className="flip-box-front"></div> */}
              </SkeletonBox>
            </div>
          );
        })}
      </div>
    </div>
  );
};
