import Header from "../../globalComponents/header/Header";
import NotFoundImage from "../../../assets/notFoundImage.png";

import "./notFoundPage.css";

const NotFoundPage = () => {
  return (
    <>
      <Header className="headerStyle" />
      <div
        className="notFoundPageContainer"
        // style={{
        //   height: "100vh",
        //   fontSize: "3rem",
        //   color: "#ef750f",
        //   display: "flex",
        // }}
      >
        <div>Page Not Found</div>
        <div className="notFoundImageContainer">
          <img src={NotFoundImage} alt="404 Not Found Page" loading="lazy" />
        </div>
        <div>Error</div>
      </div>
    </>
  );
};

export default NotFoundPage;
