import ConvertStringToHtml from "../../../../globalComponents/ConvertStringToHtml";
import ProjectHighlight from "./ProjectHighlight";

import { formatIndianCurrency } from "../../../../../helpers/helper";
import { EMPTY_OBJECT } from "../../../../../assets/constants";
import "./overview.css";

const Overview = ({ projectData = EMPTY_OBJECT }) => {
  const {
    Project_Name,
    Description,
    Address,
    RegNo = "",
    Max_Price,
    Min_Price,
    category,
  } = projectData;

  const price = `₹ ${formatIndianCurrency(
    Min_Price
  )} - ₹ ${formatIndianCurrency(Max_Price)} *`;

  const highlightData = {
    "project Type": category?.data?.attributes?.Title || "",
    price: price || "",
    address: Address || "",
  };

  return (
    <>
      {Description && (
        <section className="row overview">
          <ConvertStringToHtml htmlData={Description} />

          {/* Orion One32 is a commercial property in sector 132, Noida, with a
        promise of lifelong return. The pre-leased property has modernly
        designed Retail Shops, Business Suites, Food Court, and Office spaces.
        The gigantic façade and flawless connectivity to the NCR region attract
        investors at large. With workspaces bang on the Noida expressway, many
        corporate tycoons have already taken a step forward to invest here. The
        modernly designed luxury Retail stores at Orion One32 in Noida, Sector
        132, welcome a high footfall as it shares proximity to several posh
        residential neighbourhoods like Jaypee Klassics and Jaypee Wish Town. It
        propels business expansion and profitability ratio. */}
        </section>
      )}

      <ProjectHighlight
        projectName={Project_Name}
        highlightData={highlightData}
        regNo={RegNo}
      />
    </>
  );
};

export default Overview;
