import { FaRegShareFromSquare } from "react-icons/fa6";

import { shareContent } from "../../../../../services/shareContentSlug";

const ProjectShareBtn = ({ ...props }) => {
  const handleShareBtn = () => {
    shareContent("Wealth clinic project", "text", window.location.href);
  };
  return (
    <div {...props} onClick={handleShareBtn}>
      <FaRegShareFromSquare />
    </div>
  );
};

export default ProjectShareBtn;
