import { createSlice } from "@reduxjs/toolkit";
// createAsyncThunk
const initialState = {
  items: [], // Array to store wishlist items
  projectsId: {},
};

const wishListSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addItemToWishlist: (state, action) => {
      state.projectsId[`${action.payload.id}`] = action.payload;
      state.items.push(action.payload);
    },
    removeItemFromWishlist: (state, action) => {
      //   console.log(action.payload);
      delete state.projectsId[`${action.payload}`];
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
  },
});

export const { addItemToWishlist, removeItemFromWishlist } =
  wishListSlice.actions;
export default wishListSlice.reducer;

// lists={
// 0:{projectDetails},
// 1:{projectDetails},
// 2:{projectDetails},
// }
