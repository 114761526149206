import { useState } from "react";

import ThankYouMessage from "../ThankYouMessage";

import { submissionOnZoho } from "../../../assets/constants/zoho";
import { FORM_NAMES } from "../../../assets/constants/formNames";
import { PROCESS_MESSAGE } from "../../../assets/constants/messages";
import "./queryForm2.css";

const QueryForm2 = () => {
  const INITIAL_FORM_VALUES = {
    formName: FORM_NAMES?.ANY_ENQUIRIES_FORM_NAME,
    name: "",
    phone: "",
    email: "",
    propertyType: "",
    cityName: "",
    message: "",
  };

  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);
  const [errors, setErrors] = useState({});
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  const handleForm = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm(formValues);
    if (Object.keys(validationErrors).length === 0) {
      setIsBtnDisable(true);
      // toast("form submitted successfully");
      await submissionOnZoho(formValues);
      setIsBtnDisable(false);
      setIsSuccessfullySubmitted(true);
      setFormValues(INITIAL_FORM_VALUES);
    } else {
      setErrors(validationErrors);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleThankYouComplete = () => setIsSuccessfullySubmitted(false);

  return (
    <div className="formbold-main-wrapper">
      <div
        className="formbold-form-wrapper py-4 QueryForm-size"
        style={{ position: "relative" }}
      >
        <div className="queryFormBox px-4">
          <form onSubmit={handleForm}>
            <div className="formbold-mb-5 formbold-pt-3">
              <div className="flex flex-wrap formbold--mx-3">
                <div className="w-full sm:w-half formbold-px-3">
                  <div className="formbold-mb-5">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Full Name"
                      className="formbold-form-input"
                      value={formValues.name}
                      onChange={handleInputChange}
                      required
                    />
                    {errors.name && (
                      <span className="alertMsg">{errors.name}</span>
                    )}
                  </div>
                </div>

                <div className="w-full sm:w-half formbold-px-3">
                  <div className="formbold-mb-5">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Phone Number"
                      className="formbold-form-input"
                      value={formValues.phone}
                      onChange={handleInputChange}
                      required
                    />
                    {errors.phone && (
                      <span className="alertMsg">{errors.phone}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="formbold-mb-5">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter Your Email"
                className="formbold-form-input"
                value={formValues.email}
                onChange={handleInputChange}
                required
              />
              {errors.email && <span className="alertMsg">{errors.email}</span>}
            </div>

            <div className="formbold-mb-5">
              <select
                name="propertyType"
                id="propertyType"
                value={formValues.propertyType}
                onChange={handleInputChange}
                className="formbold-form-input"
                required
              >
                <option value="">Property Type</option>
                <option value="Residential">Residential</option>
                <option value="Commercial">Commercial</option>
                <option value="Other">Other</option>
              </select>
              {errors.propertyType && (
                <span className="alertMsg">{errors.propertyType}</span>
              )}
            </div>

            <div className="formbold-mb-5">
              <select
                name="cityName"
                id="cityName"
                value={formValues.cityName}
                onChange={handleInputChange}
                className="formbold-form-input"
                required
              >
                <option value="">City Name</option>
                <option value="Noida">Noida</option>
                <option value="Bengaluru">Bengaluru</option>
                <option value="Moradabad">Moradabad</option>
                <option value="Lucknow">Lucknow</option>
                <option value="Gurugram">Gurugram</option>
                <option value="Pune">Pune</option>
                <option value="Ghaziabad">Ghaziabad</option>
              </select>
              {errors.cityName && (
                <span className="alertMsg">{errors.cityName}</span>
              )}
            </div>

            <div className="formbold-mb-5">
              <textarea
                placeholder="Message..."
                rows="5"
                cols="30"
                name="message"
                className="formbold-form-Textarea"
                value={formValues.message}
                onChange={handleInputChange}
                required
              />
              {errors.message && (
                <span className="alertMsg">{errors.message}</span>
              )}
            </div>

            <div>
              <button
                type="submit"
                className="formbold-btn"
                disabled={isBtnDisable}
              >
                {isBtnDisable
                  ? PROCESS_MESSAGE.FORM_SUBMISSION_IN_PROGRESS
                  : "Submit Now"}
              </button>
            </div>
          </form>
        </div>
        {isSuccessfullySubmitted && (
          <ThankYouMessage onComplete={handleThankYouComplete} />
        )}
      </div>
    </div>
  );
};

export default QueryForm2;

const validateForm = (formValues) => {
  const errors = {};

  if (!formValues.name.trim()) {
    errors.name = "Full Name is required";
  }

  if (!formValues.phone.trim()) {
    errors.phone = "Phone Number is required";
  } else if (formValues.phone.length !== 10) {
    errors.phone = "Phone Number must be exactly 10 digits";
  } else if (!/^\d{10}$/.test(formValues.phone)) {
    errors.phone = "Phone Number must be numeric and 10 digits";
  }

  if (!formValues.email.trim()) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
    errors.email = "Email is invalid";
  }

  if (!formValues.propertyType) {
    errors.propertyType = "Property Type is required";
  }

  if (!formValues.cityName) {
    errors.cityName = "City Name is required";
  }

  if (!formValues.message.trim()) {
    errors.message = "Message is required";
  }

  return errors;
};
