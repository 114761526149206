import { Link, useLocation, useParams } from "react-router-dom";
import { useState, memo } from "react";

import Heading from "../../../globalComponents/molecules/Heading";
import CustomDropDown from "../../../globalComponents/customDropDown/CustomDropDown";
import CustomLink from "../../../CustomLink";

import {
  PROPERTY_TYPES,
  SORTED_BY_OPTIONS,
} from "./../../../../assets/constants/filters";

const SORTED_BTN_LABELS = [
  SORTED_BY_OPTIONS.LOW_PRICE,
  SORTED_BY_OPTIONS.HIGH_PRICE,
  SORTED_BY_OPTIONS.A_TO_Z,
];

const PropertyPageToolBar = ({ setOrder, selectedCity, properties }) => {
  const [isFilterOpen, setIsFilterOpen] = useState({
    filter: false,
    propertyType: false,
  });
  const { filter, propertyType } = isFilterOpen;

  // const [matchDropDown, setMatchDropDown] = useState(false);

  // const dropDownId = {
  //   filter: "filter-dropdown",
  //   propertyType: "property-type-dropdown",
  // };
  // const handleCloseDropDown = () => {
  //   const closeFn = () => setMatchDropDown(!matchDropDown && false);
  //   console.log(!matchDropDown && false);
  //   setTimeout(closeFn, 400);
  //   setMatchDropDown(!matchDropDown && false);
  //   return () => clearTimeout(closeFn);
  // };

  return (
    <div className="row px-2">
      <div className="col-lg-2 col-md-3 col-sm-3 col-6  border">
        <CustomDropDown
          text={"SORT BY"}
          selectType={"enabled"}
          data={SORTED_BTN_LABELS}
          styleMenu={{ height: "104px", width: "180px" }}
          children={<DropdownBtn value={setOrder} />}
          isDropDownOpen={isFilterOpen.filter}
          setIsDropDownOpen={() =>
            setIsFilterOpen({ ...isFilterOpen, filter: !filter })
          }
          // isDropDownOpen={dropDownId.filter === matchDropDown}
          // setIsDropDownOpen={() => setMatchDropDown(dropDownId.filter)}
        />
      </div>

      <div className="col-lg-2 col-md-2 col-md-3 col-sm-3 col-6 border">
        <CustomDropDown
          text={"Property Type"}
          selectType={"enabled"}
          data={PROPERTY_TYPES}
          styleMenu={{ height: "104px", width: "180px" }}
          children={<PropertyDropdownBtn />}
          isDropDownOpen={isFilterOpen.propertyType}
          setIsDropDownOpen={() =>
            setIsFilterOpen({ ...isFilterOpen, propertyType: !propertyType })
          }
          // isDropDownOpen={dropDownId.propertyType === matchDropDown}
          // setIsDropDownOpen={() => setMatchDropDown(dropDownId.propertyType)}
        />
      </div>

      {selectedCity &&
        selectedCity !== "properties" &&
        selectedCity !== URL.SEARCH && (
          <div className="col-lg-4 col-md-4 center-item">
            <Heading
              tag="h2"
              text={`New Projects in ${selectedCity}`}
              className="projectCount text-capitalize"
              fontSize="1.25rem"
            />
          </div>
        )}

      {/* {city === undefined || (city === null && null)} */}
      <div className="col-lg-4 col-md-4 center-item projectCount">
        Showing Total Projects {properties?.length}
      </div>
    </div>
  );
};

export default PropertyPageToolBar;

function DropdownBtn({ text, value: setValue }) {
  const { pathname, search, state } = useLocation();
  return (
    <Link
      to={pathname + search}
      state={state}
      onClick={() => setValue(text)}
      className="sorted-Filter del-underLine"
    >
      {text}
    </Link>
  );
}

// function PropertyDropdownBtn({ text }) {
//   const location = useLocation();
//   console.log(location?.state);

//   return (
//     <CustomLink
//       to={`/search?building-type=${text}`}
//       className="sorted-Filter del-underLine"
//       state={location?.state}
//     >
//       {text}
//     </CustomLink>
//   );
// }
const PropertyDropdownBtn = memo(({ text }) => {
  const location = useLocation();
  let { city: selectedCity } = useParams();

  return (
    <CustomLink
      to={`/search?building-type=${text}`}
      className="sorted-Filter del-underLine"
      state={location?.state || (selectedCity && { cityName: [selectedCity] })}
    >
      {text}
    </CustomLink>
  );
});
