import EmiCalculator from "../../../EmiCalculator";

const ProjectEmiCalculator = () => {
  return (
    <div className="p-0">
      <EmiCalculator />
    </div>
  );
};

export default ProjectEmiCalculator;
