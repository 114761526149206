import React, { lazy } from "react";
import { Suspense } from "react";

import MarqueeSlider from "../../globalComponents/marquee/MarqueeSlider";
import Card from "../page/template/Card";
import CardsContainer from "../../globalComponents/cardsContainer/CardsContainer";
import AboutUs from "../../homePage/aboutUs/AboutUs";
import Heading from "../../globalComponents/molecules/Heading";
import MileStones from "../../homePage/achievements/MileStones";
import FinalExplore from "../../homePage/exploreProjectsSection/FinalExplore";
import AdvantageSection from "../../homePage/advantageSection/AdvantageSection";
import FliperCardSlider from "../../globalComponents/slickCrousel/FliperCardSlider";
// import QuerySectionTemplate from "../../homePage/querySectionTemplate/QuerySectionTemplate";
// import TemplateMob from "./template/TemplateMob";
import NewTemplateSlider from "../../homePage/templateSlider/NewTemplateSlider";

const QuerySectionTemplate = React.lazy(() =>
  import("../../homePage/querySectionTemplate/QuerySectionTemplate")
);

const EventPopUp = lazy(() => import("../../homePage/EventPopUp"));

const HomePages = () => {
  return (
    <div>
      {/* <TemplateMob text="Real Estate Made Real Easy" searchBar="enabled" /> */}
      <NewTemplateSlider text="Real Estate Made Real Easy" height="20rem" />
      <MarqueeSlider />
      <Card />
      <CardsContainer />
      <AboutUs />
      <section className="de" style={{ backgroundColor: "#000" }}>
        <div className="container-fluid my-4">
          <div className="d-flex justify-content-center">
            <Heading
              text={"OUR MILESTONES"}
              className="heading text-center text-white mt-2"
              color={"var(--global-heading-color)"}
              fontWeight={700}
            />
          </div>
          <h4 className="gh mt-2 text-center">
            The Milestones Of Wealth Clinic
          </h4>
          <MileStones />
        </div>
      </section>
      <section className="container-fluid mt-5">
        {/* <h4 className="text-lg-center fw-bold fs-1">OUR NEW LAUNCHES</h4> */}
        <div className="d-flex justify-content-center">
          <Heading
            text={"OUR NEW LAUNCHES"}
            color={"var(--global-heading-color)"}
            fontWeight={700}
            className="text-center heading"
          />
        </div>
        <h4 className="gh text-center">
          Find The Fascinating New Launches And Projects
        </h4>
        {/* <InfiniteSlides
          settings={settings}
          className="infiniteSlider-container container-fluid"
          childElement={<TextOnImg />}
          data={SLIDES}
        /> */}

        <FliperCardSlider />
      </section>

      <div className="container-fluid my-4">
        <div className="d-flex justify-content-center">
          <Heading
            text={"EXPLORE PROJECTS"}
            color={"var(--global-heading-color)"}
            fontWeight={700}
            className="heading text-dark text-center"
          />
        </div>
        <h4 className="text-center" style={{ fontSize: "14px", color: "#888" }}>
          Find Your Dream Property
        </h4>
        <FinalExplore />
      </div>

      <AdvantageSection />
      <Suspense fallback="">
        <QuerySectionTemplate />
      </Suspense>
      {/* <GoogleReview /> */}
      {/* <StickySocialIcons />
            <Disclaimer /> */}

      <Suspense fallback="">
        <EventPopUp />
      </Suspense>
    </div>
  );
};

export default HomePages;
