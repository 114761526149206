// import { toast } from "react-toastify";
import { useState } from "react";

import InfiniteSlides from "../../globalComponents/slickCrousel/InfiniteSlides";
import Heading from "../../globalComponents/molecules/Heading";
import ThankYouMessage from "../../globalComponents/ThankYouMessage";

import useApiFetcher from "../../../hooks/useApiFetcher";
import { getFullUrl } from "../../../assets/constants/apiUrls";
import { propertyParams } from "../../../helpers/params";
import { submissionOnZoho } from "../../../assets/constants/zoho";
import { FORM_NAMES } from "../../../assets/constants/formNames";
// import { SLIDES } from "../../../assets/IMG";
import { PROCESS_MESSAGE } from "../../../assets/constants/messages";
import "./aboutUs.css";

const settings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  speed: 500,
};

const AboutUs = () => {
  const url = getFullUrl(
    "/api/projects?populate[0]=Image&pagination[limit]=10&filters[newLaunches]=true"
  );
  const [projects, error, isLoading] = useApiFetcher(url);

  if ((!projects, isLoading)) return null;
  if (error) return <div>{error}</div>;

  const SLIDES = formatProjectsImg(projects);

  return (
    <div className="container border d-flex align-items-center justify-content-center flex-wrap callBackForm">
      <div className="col-md-8 my-2 newClass">
        <div className="text-Item">
          <Heading
            text={"Request a Call Back"}
            className="callback"
            fontWeight={700}
            color="var(--global-heading-color)"
          />
          <p className="callback2">
            Looking for a home that brings happiness or property that brings
            prosperity?
            <br /> Contact us - Get Real Estate with Unreal Deals!
          </p>
        </div>
        <div className="d-none d-md-block">
          <InfiniteSlides
            settings={settings}
            data={SLIDES}
            className="about-Us-Page-Slider"
          />
        </div>
      </div>
      <div className="col-md-3">
        <EnquiryForm />
      </div>
    </div>
  );
};

function formatProjectsImg(projects) {
  return projects?.map((project) => {
    const { img, altTag, imgTitle } = propertyParams(project);
    return {
      img,
      alt: altTag,
      title: imgTitle,
    };
  });
}

export default AboutUs;

export const EnquiryForm = () => {
  const INITIALS_FIELDS = {
    formName: FORM_NAMES?.REQUEST_CALL_BACK_FORM,
    name: "",
    phone: "",
    budget: "",
    propertyType: "",
    email: "", //temp@email.com
  };

  const [formValues, setFormValues] = useState(INITIALS_FIELDS);

  const [errors, setErrors] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm(formValues);
    if (Object.keys(validationErrors).length === 0) {
      setIsBtnDisable(true);
      // toast("Form Submitted");
      await submissionOnZoho(formValues);
      setIsBtnDisable(false);
      setIsSuccessfullySubmitted(true);
      setFormValues(INITIALS_FIELDS);
    } else {
      setErrors(validationErrors);
    }
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleOptionChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);
    setFormValues({ ...formValues, propertyType: value });
  };

  const handleThankYouComplete = () => setIsSuccessfullySubmitted(false);

  return (
    <div className="signUpForm">
      <div style={{ position: "relative" }}>
        <form
          className="w-100 border"
          onSubmit={handleFormSubmit}
          // style={{ position: "relative" }}
        >
          <div className="signUpHeader d-none d-md-block">
            <div className="fw-bold my-h">Enquiry Now</div>
          </div>
          <input
            placeholder="Full Name"
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            required
          />
          {errors.name && <span className="error">{errors.name}</span>}
          <input
            placeholder="Mobile Number"
            name="phone"
            type="number"
            maxLength={10}
            value={formValues.phone}
            onChange={handleInputChange}
            required
          />
          {errors.phone && <span className="error">{errors.phone}</span>}
          <input
            placeholder="Budget"
            name="budget"
            value={formValues.budget}
            onChange={handleInputChange}
            required
          />
          {errors.budget && <span className="error">{errors.budget}</span>}
          <select
            id="options"
            value={selectedOption}
            onChange={handleOptionChange}
            required
          >
            <option value="">Select Property Type</option>
            <option value="Residential">Residential</option>
            <option value="Commercial">Commercial</option>
            <option value="Other">Other</option>
          </select>
          {errors.propertyType && (
            <span className="error">{errors.propertyType}</span>
          )}
          {/* Uncomment the below section if you need the city type selection */}
          {/* <select
            id="options"
            value={selectedCityOption}
            onChange={handleCityOptionChange}
          >
            <option value="">City Type</option>
            <option value="Delhi">Delhi</option>
            <option value="Noida">Noida</option>
            <option value="Moradabad">Moradabad</option>
            <option value="Ghaziabad">Ghaziabad</option>
            <option value="Greater Noida">Greater Noida</option>
            <option value="Rishikesh">Rishikesh</option>
          </select> */}
          <button type="submit" disabled={isBtnDisable}>
            {isBtnDisable
              ? PROCESS_MESSAGE.FORM_SUBMISSION_IN_PROGRESS
              : "Submit"}
          </button>
        </form>
        {isSuccessfullySubmitted && (
          <ThankYouMessage onComplete={handleThankYouComplete} />
        )}
      </div>
    </div>
  );
};

const validateForm = (formValues) => {
  const errors = {};

  if (!formValues.name.trim()) {
    errors.name = "Full Name is required";
  }

  if (!formValues.phone.trim()) {
    errors.phone = "Mobile Number is required";
  } else if (!/^\d{10}$/.test(formValues.phone)) {
    errors.phone = "Mobile Number must be 10 digits";
  }

  if (!formValues.budget.trim()) {
    errors.budget = "Budget is required";
  }

  if (!formValues.propertyType) {
    errors.propertyType = "Property Type is required";
  }

  return errors;
};
