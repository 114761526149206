const Disclaimer = ({ projectData }) => {
  const disclaimerContainer = {
    maxHeight: "400px",
    overflowY: "auto",
  };
  return (
    <section className="pb-4 pb-lg-0 pb-md-0" style={disclaimerContainer}>
      {projectData?.Project_Disclaimer}
    </section>
  );
};

export default Disclaimer;
