export const META_TAGS = {
  title: "https://new.wealth-clinic.com/",
  description:
    "Wealth Clinic stands as the premier Real Estate Consultancy Company in India. Discover an extensive collection of opulent residential and commercial properties across India. Embark on your exploration now",
  link: "Wealth Clinic - Best Real Estate Consultancy Company in India",
};

export const INITIAL_META_TAG = {
  title: "",
  description: "",
  link: "",
};
