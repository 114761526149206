import { useSelector } from "react-redux";
import { useState } from "react";

import { CiHeart, CiSearch } from "react-icons/ci";
import { PiUserCircleLight } from "react-icons/pi";
import WishListPopUp from "../../../pages/wishList";
import LoginPopUp from "../../Login/LoginPopUp";
import SearchBarPopUp from "../../../advancedSearchBar/SearchBarPopUp";
import useDeviceSize from "./../../../../hooks/useDeviceSize";

function HeaderSideButtons() {
  const whishListCount = useSelector((state) => state.wishlist.items.length);

  const [isOpenLoginPopUp, setIsOpenLoginPopUp] = useState(false);
  const [isOpenWishList, setIsOpenWishList] = useState(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  // const [isOpen, setIsOpen] = useState({
  //   loginPopUp: false,
  //   wishList: false,
  //   searchBarOpen: false,
  // });

  const handleLoginPopUp = () => setIsOpenLoginPopUp(!isOpenLoginPopUp);
  const handleSearchBar = () => {
    setIsOpenWishList(false);
    setIsSearchBarOpen(!isSearchBarOpen);
  };
  const handleWishListPopUp = () => {
    setIsSearchBarOpen(false);
    setIsOpenWishList(!isOpenWishList);
    // dispatch(setOpenComponentById("WishListIdBtn"));
  };
  const isMobile = useDeviceSize();

  return (
    <div className="superGroup">
      <div className="bgGrouping">
        <div>
          <PiUserCircleLight onClick={handleLoginPopUp} />
        </div>
        <div className="wishListHeaderBtn">
          <CiHeart onClick={handleWishListPopUp} />
          {whishListCount > 0 && (
            <div className="wishListCount">{whishListCount}</div>
          )}
        </div>
        <div>
          <CiSearch onClick={handleSearchBar} />
        </div>
      </div>

      {!isMobile && (
        <div
          className="menuBtn header-btn"
          data-bs-toggle="modal"
          data-bs-target="#sidebarMenu"
        >
          <i className="bi bi-list"></i>
        </div>
      )}

      {isOpenLoginPopUp && <LoginPopUp onClose={handleLoginPopUp} />}
      <div>
        {isOpenWishList && (
          <WishListPopUp
            onClose={handleWishListPopUp}
            onMouseLeft={handleWishListPopUp}
          />
        )}

        {isSearchBarOpen && (
          <SearchBarPopUp
            isOpen={isSearchBarOpen}
            onClose={handleSearchBar}
            // getSearchedValue={setSearchedBarValue}
            id="headerSearchbar"
          />
        )}
      </div>
    </div>
  );
}

export default HeaderSideButtons;
