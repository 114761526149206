import { useLocation, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";

const PaginationCustom = ({ cb, state, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  //   const [searchParams] = useSearchParams();
  //   const pageNumber = searchParams.get("page");
  //   const [page, setPage] = useState(pageNumber || 1);

  const handlePagination = (e, selectPageNo) => {
    navigate(`${location?.pathname}?page=${selectPageNo}`, {
      state: { ...state },
    });
    // setPage(selectPageNo);
    // cb(page);
  };
  return (
    <div>
      <Pagination {...props} onChange={handlePagination} />
    </div>
  );
};

export default PaginationCustom;
