import useApiFetcher from "../../../../../hooks/useApiFetcher";
import { getFullUrl } from "../../../../../assets/constants/apiUrls";

import SimilarProjectCard from "./SimilarProjectCard";

const SimilarProjects = ({ projectData }) => {
  const cityName = projectData.create_city.data.attributes.City_Name || "";

  const url = getFullUrl(
    `/api/projects?filters[create_city][City_Name]=${cityName}&populate[create_city]=*&populate[Image]=*&pagination[pageSize]=3`
  );

  const [similarProjectsData, isLoading, error] = useApiFetcher(url);

  if (error) return <div>{error}</div>;
  if (!similarProjectsData || isLoading) return <div>Loading....</div>;

  return (
    <div className="row my-2 my-lg-0 my-md-3">
      {similarProjectsData.map((cardData, idx) => {
        return (
          <div
            key={idx}
            className="my-2 my-sm-2 col-12 col-lg-4 col-md-6 col-sm-6"
          >
            <SimilarProjectCard similarProjectData={cardData} />
          </div>
        );
      })}
    </div>
  );
};

export default SimilarProjects;
