import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <section className="container-fluid">
      <div className="center-item" style={{ height: "50vh" }}>
        <CircularProgress />
      </div>
    </section>
  );
};

export default Loader;
