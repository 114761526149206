import Heading from "../../../../globalComponents/molecules/Heading";
import ConnectivityNearAreas from "./ConnectivityNearAreas";

import { extractNearAreasAttributes } from "./helper";
import "./mapConnectivity.css";

const MapConnectivity = ({ projectData }) => {
  const CONNECTIVITY = extractNearAreasAttributes(projectData?.Near_Areas);

  const LOCATION_URL = projectData?.Location_Map?.Location_Map;
  const iframeSrc = extractIframeSrc(LOCATION_URL);

  return (
    <section className="row">
      <div className="col-12 col-xs-12 col-lg-6 col-md-5 col-sm-6 px-sm-0">
        <iframe
          className="locationGoogleMap"
          key="orion-one-32-map"
          title="googleMap"
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          // src="https://www.google.com/maps/embed/v1/place?key=API_KEY&q=Space+Needle,Seattle+WA"
          src={iframeSrc}
        />
      </div>
      <div className="col-12 col-lg-6 col-md-7 col-sm-6 pl-sm-0">
        <Heading
          text="Near By Attractions"
          fontSize="1.25rem"
          className="fw-bold mt-1"
        />
        <ConnectivityNearAreas connectedAreas={CONNECTIVITY} />
      </div>
    </section>
  );
};

export default MapConnectivity;

const extractIframeSrc = (iframeString) => {
  const srcMatch = iframeString && iframeString.match(/src="([^"]+)"/);
  return srcMatch ? srcMatch[1] : "";
};
