// import { ToastContainer } from "react-toastify";
import { lazy } from "react";

import CopyRightSection from "./globalComponents/copyRightSection/CopyRightSection";
import TextContent from "./globalComponents/newFooterContent/TextContent";
import CopyRightFooter from "./globalComponents/CopyRightFooter/CopyRightFooter";
import StickySocialIcons from "./globalComponents/stickySocialIcons/StickySocialIcons";
import Footer2 from "./globalComponents/footer2/Footer2";
import FooterMob from "./mobileComponents/FooterMob";
// import BackButton from "./BackButton";
import PatchButton from "./PatchButton";

import useDeviceSize from "../hooks/useDeviceSize";
import useScrollOnTop from "../hooks/useScrollOnTop";
import "react-toastify/dist/ReactToastify.css";

const LazyMicrosoftClarityScript = lazy(() =>
  import("./MicrosoftClarityScript")
);

const Body = ({ children }) => {
  const isMobile = useDeviceSize();
  // useScrollOnTop(isMobile ? 0 : 500);
  useScrollOnTop(0);
  return (
    <div className="body">
      <LazyMicrosoftClarityScript />

      {/* <Disclaimer /> */}
      {children}
      {isMobile ? (
        <FooterMob />
      ) : (
        <>
          <Footer2 />
          <CopyRightSection />
          <TextContent />
          <CopyRightFooter />
          <StickySocialIcons />
          {/* <BackButton /> */}
        </>
      )}
      <PatchButton />
      {/* <ToastContainer position="bottom-center" /> */}
    </div>
  );
};

export default Body;
