import { Link } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import { useEffect, useState } from "react";

import HeaderSideButtons from "../globalComponents/header/HeaderSideButtons";
import MobileSideBar from "./MobileSideBar";

import { COMPANY_LOGO } from "../../assets/constants/companyLogos";
import "./module.css";
import "./mobHeader.css";

const HeaderMob = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrollY, setIsScrollY] = useState(0);

  const handleScroll = () => setIsScrollY(window.scrollY);
  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrollY]);

  return (
    <div className="">
      <Navbar expand="lg" className="header  rounded-2 mobHeader ">
        <Container className="mobHeaderInnerBox center-item">
          <Navbar.Brand className="col-2" as={Link} to="/">
            <img
              src={COMPANY_LOGO}
              alt="company_logo"
              className="img-fluid header-logo"
            />
          </Navbar.Brand>

          <div className="mobileHeaderSideButtons d-flex align-content-center justify-content-end col-10 ">
            <HeaderSideButtons />
            <div className="center-item" onClick={toggleMenu}>
              <i className="bi bi-list"></i>
            </div>
          </div>

          {isOpen ? <MobileSideBar /> : null}
        </Container>
      </Navbar>
    </div>
  );
};

export default HeaderMob;
