import { Helmet } from "react-helmet";

const MetaTagHelmet = ({
  title: metaTitle,
  description: metaDescription,
  link: metaLink,
}) => {
  // useEffect(() => {
  //   let metaTitle = document.querySelector('name="title');
  //   if (!metaTitle) {
  //     metaTitle = document.createElement("meta");
  //   }
  //   metaTitle.name = "title";
  // }, []);
  return (
    <Helmet>
      <meta name="title" content={metaTitle || ""} />
      <meta charSet="utf-8" />
      <title>{metaTitle ? metaTitle : ""}</title>
      <meta name="description" content={metaDescription || ""} />
      {/* <meta name="author" content="wealth-clinic.com" /> */}
      <link rel="canonical" href={metaLink || window.location.href} />
    </Helmet>
  );
};

export default MetaTagHelmet;
