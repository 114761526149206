import { BsAmd } from "react-icons/bs";

import Heading from "../../../globalComponents/molecules/Heading";
import ReadMore from "../../../globalComponents/molecules/ReadMore";

import useDeviceSize from "../../../../hooks/useDeviceSize";
import "./ourMission.css";

const OurMission = ({ data }) => {
  const { Our_Mission_Title, Our_Mission_Description, Our_Mission_Image } =
    data;
  const mission_Image = Our_Mission_Image?.data?.attributes?.url;
  const isMobile = useDeviceSize();

  const [title, description, MISSION_IMG] = [
    Our_Mission_Title,
    Our_Mission_Description,
    mission_Image,
  ];

  return (
    <div className="my-5 border border-danger border-0 ">
      <div className="container" style={{ background: isMobile && "#dbd9ed" }}>
        {isMobile && (
          <Heading
            text={title}
            color="#000"
            fontWeight="700"
            className="ourMissionTitle horizontal-center py-2 py-sm-0"
          />
        )}
        <div className="row vertical-center flex-column-reverse flex-lg-row">
          <div className="col-12 col-lg-6 mb-sm-4 mb-lg-0">
            <div className="missionSecBg1">
              {!isMobile && (
                <Heading
                  text={title}
                  color="#000"
                  fontWeight="700"
                  className="ourMissionTitle"
                />
              )}
              <p className="ourMissionPara">
                <ReadMore
                  ckEditorDescription={description}
                  lineHeight={isMobile ? "8rem" : "15rem"}
                  showLine="5"
                  btnStyle={{
                    color: "#7b6fe7",
                    marginTop: ".5rem",
                  }}
                />
                {!isMobile && (
                  <div>
                    <BsAmd
                      className="icon iconPlacement"
                      size={110}
                      color={"#7B6FE7"}
                    />
                  </div>
                )}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <center className="ourMissionImgBox">
              <img
                className="bg-mission"
                alt="our mission img"
                src={MISSION_IMG}
              />
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurMission;

/* <div className="container">
  <div className="row vertical-center">
    <div className="col-12">
      <div className="missionSecBg1">
        <Heading
          text={title}
          color="#000"
          fontWeight="700"
          fontSize="24px"
          className="ourMissionTitle"
        />
        <center className="ourMissionImgBox">
          <img className="bg-mission" alt="" src={MISSION_IMG} />
        </center>
      </div>
      <div className="col-12">
        <p className="ourMissionPara">
          <ReadMore
            ckEditorDescription={description}
            lineHeight={"5rem"}
            showLine="5"
            btnStyle={{
              color: "#7b6fe7",
            }}
          />
          <div></div>
        </p>
      </div>
    </div>
  </div>
</div>; */
