import { TbDeviceVisionPro } from "react-icons/tb";

import Heading from "../../../globalComponents/molecules/Heading";
import CkEditorContentDisplay from "../../../globalComponents/CkEditorContentDisplay/CkEditorContentDisplay";

import useDeviceSize from "../../../../hooks/useDeviceSize";
import "./ourVision1.css";

const OurVision = ({ data }) => {
  const { Our_Vision_Title, Our_Vision_Description, Our_Vision_Image } = data;
  const vision_Image = Our_Vision_Image?.data?.attributes?.url;
  const isMobile = useDeviceSize();

  const [title, description, img] = [
    Our_Vision_Title,
    Our_Vision_Description,
    vision_Image,
  ];

  return (
    <div className="my-5">
      <div className="container" style={{ background: isMobile && "#f2cbc5" }}>
        {isMobile && (
          <Heading
            text={title}
            color="#000"
            fontWeight="700"
            className="visionTitle horizontal-center py-2"
          />
        )}
        <div className="row vertical-center">
          <div className="col-12 col-lg-6">
            <center className="ourVisionImageBg">
              <img className="bg-vision" alt="our Vision Img" src={img} />
            </center>
          </div>
          <div className="col-12 col-lg-6">
            <div className="visionSecBg1">
              {!isMobile && (
                <Heading
                  text={title}
                  color="#000"
                  fontWeight="700"
                  className="visionTitle"
                />
              )}
              <p className="visionPara">
                {description?.map((item, idx) => (
                  <CkEditorContentDisplay key={idx} content={item} />
                ))}
                {!isMobile && (
                  <div>
                    <TbDeviceVisionPro
                      className="icon iconPlacement"
                      size={112}
                      color={"#E28A7C"}
                    />
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurVision;

/* <div className="row vertical-center">
  <div className="col-12">
    <Heading
      text={title}
      color="#000"
      fontWeight="700"
      className="visionTitle horizontal-center py-2"
    />
    <center className="ourVisionImageBg">
      <img className="bg-vision" alt="" src={img} />
    </center>
  </div>
  <div className="col-12 mb-sm-4 mb-lg-0">
    <div className="visionSecBg1">
      <p className="visionPara">
        {description?.map((item, idx) => (
          <CkEditorContentDisplay key={idx} content={item} />
        ))}
      </p>
    </div>
  </div>
</div> */
