import { useState } from "react";
import CircleTexture from "../../../../assets/cricle_bg.png";
import styles from "./newReadMoreCard.module.css";

const NewReadMoreCard = (props) => {
  const { color, style, img, readMoreTitle, readMoreDesc } = props;
  const newStyle = style;

  return (
    <div
      className={styles.bgColBlock}
      style={{ background: color, ...newStyle }}
    >
      <div className="row">
        <div className="col-md-3 col-xs-3">
          <img className={styles.pillarsImg} src={img} alt="pilar1" />
        </div>
        <div className="col-md-9 col-xs-9">
          <img className={styles.tryBubble} src={CircleTexture} alt="pilar2" />
        </div>
        <p className={styles.readMoreTitle}>{readMoreTitle}</p>
        {/* <p className={styles.readMoreDesc}>{readMoreDesc}</p> */}
        <ReadMore description={readMoreDesc} />
      </div>
    </div>
  );
};
export default NewReadMoreCard;

const ReadMore = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>
      <div
        className={`${styles.readMoreDesc} ${
          isExpanded ? styles.expandedDescription : ""
        }`}
      >
        {description}
      </div>
      <button onClick={toggleReadMore} style={{ display: "none" }}>
        {isExpanded ? "Read Less" : "Read More"}
      </button>
    </>
  );
};
