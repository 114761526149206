import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

import SearchSuggestionBar from "./SearchSuggestionBar";

import { EMPTY_OBJECT } from "../../assets/constants";
import "./searchBarPopUp.css";

const SearchBarPopUp = ({ isOpen, onClose }) => {
  const [searchBarValue, setSearchedBarValue] = useState(EMPTY_OBJECT);
  const navigate = useNavigate();

  return (
    <>
      {isOpen && (
        <div className="searchBarPopUp" onMouseLeave={onClose}>
          <div className="w-100 d-flex">
            <div className="w-100 d-flex rounded-1 pl-1">
              <SearchSuggestionBar
                className="w-100"
                getSearchedValue={setSearchedBarValue}
                navigate={navigate}
              />
            </div>
            <button className="button">
              <Link
                to={"/search"}
                state={{ searchBarValue }}
                className="searchBtn_1 text-white text-decoration-none"
              >
                Search
              </Link>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchBarPopUp;

// export function SearchSuggestionBar({ placeHolder, getSearchedValue }) {
//   const url = "https://bold-approval-c005df0fb8.strapiapp.com/api/projects?";
//   const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);
//   const [value, setValue] = useState("");
//   const [filteredSuggestions, setFilteredSuggestions] = useState(EMPTY_ARRAY);

//   const handleInput = (event) => {
//     setValue(event.target.value);
//   };

//   const handleOpenSearchSuggestion = () => setIsSuggestionOpen(true);
//   const handleCloseSearchSuggestion = () =>
//     setTimeout(() => setIsSuggestionOpen(false), 300);

//   const [searchSuggestions, isLoading, error] = useApiFetcher(url);

//   useEffect(() => {
//     if (value) {
//       const filterData = searchSuggestions?.filter((item) => {
//         const { Project_Name, Project_Configuration, Address } =
//           item?.attributes || EMPTY_OBJECT;
//         return (
//           Project_Name.toLowerCase().includes(value.toLowerCase()) ||
//           Project_Configuration.toLowerCase().includes(value.toLowerCase()) ||
//           Address.toLowerCase().includes(value.toLowerCase())
//         );
//       });
//       setFilteredSuggestions(filterData);
//     } else {
//       setFilteredSuggestions(EMPTY_ARRAY);
//     }
//   }, [value, searchSuggestions]);

//   if (error) return <div>{error.message}</div>;
//   if (isLoading || !searchSuggestions) return null;

//   const handleSelectValue = (value, keyName) => {
//     if (getSearchedValue) {
//       getSearchedValue({ [keyName]: value });
//     }
//     setValue(value);
//   };

//   return (
//     <div className="input-width_1 d-flex">
//       {/* <div className="searchBarLogo fw-bold">
//         <IoSearchOutline />
//       </div> */}
//       <input
//         value={value}
//         placeholder={placeHolder || "Search for locality, Property or Project"}
//         onChange={handleInput}
//         onClick={handleOpenSearchSuggestion}
//         onBlur={handleCloseSearchSuggestion}
//         onFocus={handleOpenSearchSuggestion}
//       />

//       {isSuggestionOpen && filteredSuggestions.length > 0 && (
//         <div className="inputSuggestions_1">
//           {filteredSuggestions?.map((item, idx) => {
//             const { Project_Name, Project_Configuration, Address } =
//               item?.attributes || EMPTY_OBJECT;

//             return (
//               <div key={idx}>
//                 {Project_Name && (
//                   <li
//                     onClick={() =>
//                       handleSelectValue(Project_Name, "Project_Name")
//                     }
//                   >
//                     {Project_Name}
//                   </li>
//                 )}
//                 {Project_Configuration && (
//                   <li
//                     onClick={() =>
//                       handleSelectValue(
//                         Project_Configuration,
//                         "Project_Configuration"
//                       )
//                     }
//                   >
//                     {Project_Configuration}
//                   </li>
//                 )}
//                 {Address && (
//                   <li onClick={() => handleSelectValue(Address, "Address")}>
//                     {Address}
//                   </li>
//                 )}
//               </div>
//             );
//           })}
//         </div>
//       )}
//     </div>
//   );
// }
