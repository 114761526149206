import { toast } from "react-toastify";
import { ImLocation2 } from "react-icons/im";
import { FaCircleCheck } from "react-icons/fa6";
import { BsBuildings } from "react-icons/bs";
import { PiCurrencyInrFill } from "react-icons/pi";
import { useState } from "react";

import ButtonDarkBlue from "../../../../globalComponents/molecules/ButtonDarkBlue";
import Heading from "../../../../globalComponents/molecules/Heading";
import DownloadPopUpForm from "./DownloadPopUpForm";

import useApiFetcher from "../../../../../hooks/useApiFetcher";
import useDownload from "./../../../../../hooks/useDownload";
import { getPropertyPopulatedFieldsUrlById } from "../../../../../assets/constants/apiUrls";
import { getMethod } from "../../../../../services";
import "./projectDetailsSection.css";

const ProjectDetailsSection = ({ projectDetail }) => {
  const {
    projectName,
    address,
    regNo,
    minPrice,
    maxPrice,
    configuration,
    propertyId,
  } = projectDetail;

  const [isOpen, setIsOpen] = useState(false);
  const [formName, setFormName] = useState(projectName);
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [btnName, setBtnName] = useState(null);

  const API_URL = getPropertyPopulatedFieldsUrlById(
    ["Add_Price_List", "Brochures"],
    propertyId
  );

  const handlePopUp = () => setIsOpen(!isOpen);

  const handleDownloadForm = (btnName = "") => {
    const formattedBtnName = btnName.split("_").join(" ");
    setBtnName(btnName);

    setIsOpen(!isOpen);
    setFormName(projectName + " " + formattedBtnName);
  };

  const { downloadPdf, isDownloading, setIsLoading } = useDownload();

  const [checkPdfData, isLoading, error] = useApiFetcher(API_URL);
  if (!checkPdfData || isLoading) return null;
  if (error) return <div>{error.message}</div>;

  const isBrochureAvailable = checkPdfData?.attributes?.Add_Price_List;
  const isPriceListAvailable = checkPdfData?.attributes?.Brochures?.length > 0;

  const DOWNLOAD = {
    PRICE_LIST_BTN: "Add_Price_List",
    BROCHURES_BTN: "Brochures",
    // PRICE_LIST_BTN: "Add Price List",
  };

  const handleDownloadPdf = async (btnName) => {
    const formattedBtnName = btnName.split("_").join(" ");
    const API_URL = getPropertyPopulatedFieldsUrlById(
      [`${btnName}`],
      propertyId
    );

    setIsLoading(true);
    // setError(null);
    try {
      const response = await getMethod(API_URL); // Fetch list of PDFs
      const pdfUrls = response?.data?.attributes?.[btnName];

      const allPdfUrls =
        btnName === DOWNLOAD.BROCHURES_BTN
          ? extractBrochuresPdfUrls(pdfUrls)
          : extractPriceListPdfUrls(pdfUrls);

      if (!allPdfUrls) {
        toast.warn(`${formattedBtnName} Not available`);
      }
      for (const url of allPdfUrls) {
        await downloadPdf(url, `Downloading ${btnName}`);
      }

      setIsLoading(false);
      handlePopUp();
    } catch (error) {
      console.error("Error fetching PDFs:", error);
      // setError("Failed to fetch PDFs.");
      setIsLoading(false);
    }

    function extractBrochuresPdfUrls(pdfArray) {
      return pdfArray.map((pdf) => {
        const pdfUrl = pdf?.Brochure?.data?.attributes?.url;
        return pdfUrl;
      });
    }

    function extractPriceListPdfUrls(pdfPriceList) {
      return pdfPriceList?.Price_List?.data.map((priceList) => {
        const pdfUrl = priceList?.attributes?.url;
        return pdfUrl;
      });
    }
  };

  return (
    <div className="row m-0 py-2 px-lg-3 px-md-3 px-0">
      <Heading
        tag="h1"
        text={projectName}
        color="#0B2C3D"
        fontSize="1.75rem"
        fontWeight="700"
        className="mt-0 mt-lg-3 mt-md-3"
      />

      <div className="row px-0 mx-0">
        <div className="col-md-6">
          <ImLocation2 className="projectDetailsIcons" />
          <span>{address}</span>
        </div>
        <div className="col-md-6">
          <div className="vertical-center">
            <BsBuildings className="projectDetailsIcons" />
            <span className="lineClamp1" title={configuration || ""}>
              {configuration && configuration.split("|").join(",")}
            </span>
          </div>
        </div>
        <div className="col-md-6">
          <FaCircleCheck className="projectDetailsIcons" />
          <span>RERA : </span> {regNo}
        </div>
        <div className="col-md-6">
          <PiCurrencyInrFill className="projectDetailsIcons" />
          <span>
            {minPrice} - {maxPrice}
          </span>
        </div>
      </div>

      <div className="row px-0 mx-0 justify-content-center mt-2 mt-lg-3">
        <div className="col-lg-6 col-md-6"></div>
        <div className="col-6 col-lg-3 col-md-3">
          {isPriceListAvailable && (
            <ButtonDarkBlue
              name={`${
                isDownloading ? "Downloading..." : "Download PriceList"
              }`}
              className="rounded-1"
              onClick={() => handleDownloadForm(DOWNLOAD.PRICE_LIST_BTN)}
              disabled={isDownloading}
            />
          )}
        </div>
        <div className="col-6 col-lg-3 col-md-3">
          {isBrochureAvailable && (
            <ButtonDarkBlue
              onClick={() => handleDownloadForm(DOWNLOAD.BROCHURES_BTN)}
              disabled={isDownloading}
              name={`${isDownloading ? "Downloading..." : "Download Brochure"}`}
              className="rounded-1"
            />
          )}
        </div>
      </div>
      {isOpen && (
        <DownloadPopUpForm
          isOpen={isOpen}
          onClose={handlePopUp}
          formName={formName}
          handleDownloadPdfs={() => handleDownloadPdf(btnName)}
        />
      )}
    </div>
  );
};

export default ProjectDetailsSection;
