import Header from "../../globalComponents/header/Header";
import Heading from "../../globalComponents/molecules/Heading";
import HeaderMob from "../../mobileComponents/HeaderMob";
import AdvancedSearchBar from "../../advancedSearchBar/AdvancedSearchBar";
// import SearchBar from "../molecules/SearchBar";
// import MobileSearchBar from "../../mobileComponents/page/template/MobileSearchBar";

import TEMPLATE_BACKGROUND from "./templateBackground.webp";
import useDeviceSize from "../../../hooks/useDeviceSize";
import "./newTemplateSlider.css";
// import "./templateSlider.css";

const TemplateSlider = ({ searchBar, text, style, height, textImg }) => {
  const isMobile = useDeviceSize();

  let newStyle = style;
  let heightIs = height ? height : isMobile ? "40vh" : "100vh";

  return (
    <section className="container-fluid p-0">
      <div
        className="video-container"
        style={{
          height: heightIs,
          ...newStyle,
        }}
      >
        {/* <video autoPlay loop muted>
          <source src={VIDEO_URL} type="video/mp4" />
        </video> */}
        <div className="templateContainer">
          <ImgCard imgHeight={height} />
        </div>
        <div className="content-on-top">
          <div className="header-container">
            {isMobile ? <HeaderMob /> : <Header />}
          </div>

          <div className="searchBar-container">
            {text && text !== "" ? (
              <Heading
                tag="h1"
                text={text}
                fontSize="2.5rem"
                fontWeight="900"
                color="#fff"
                className="text-center text-capitalize fs-1"
              />
            ) : null}

            {searchBar === "enabled" ? (
              <div className="d-flex justify-content-center">
                <AdvancedSearchBar />
              </div>
            ) : null}

            <p className="text-white text-center fs-lg">
              The Most Followed Real Estate Consultant in India
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TemplateSlider;

export const ImgCard = ({ imgHeight }) => {
  return (
    <>
      <div className="templateSliderContainer">
        <div className="h-100">
          <img
            className="img-fluid"
            alt={"wealth-clinic"}
            title={"wealth clinic template"}
            src={`${TEMPLATE_BACKGROUND}`}
            loading="lazy"
            style={{ height: imgHeight || "100vh" }}
          />
        </div>
      </div>
    </>
  );
};
