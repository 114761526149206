import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../../assets/constants";
import "./faqs.css";

const Faqs = ({ projectData }) => {
  const Faqs = projectData?.Faqs || EMPTY_ARRAY;

  return (
    <section className="faqContainer">
      {Faqs?.map((faq = EMPTY_OBJECT, idx) => {
        const { Question: question = "", Answer: answer = "" } = faq;
        return (
          <div key={idx} className="faq-box">
            <div className="questionBox">
              <span className="faq-label">Que </span>
              <span className="faq-question">{question}</span>
            </div>

            <div className="faq-divider"></div>

            <div className="answerBox">
              <span className="faq-label">Ans </span>
              <span className="faq-answer">{answer}</span>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default Faqs;
