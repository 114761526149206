import { EMPTY_ARRAY } from "../../../../../assets/constants";
import "./amenities.css";

// const AMENITIES = [
//   {
//     name: "CCTV Surveillance",
//     img: "https://bold-approval-c005df0fb8.media.strapiapp.com/cctv_camera_a0b88b2052.png",
//   },
//   {
//     name: "24/7 Security",
//     img: "https://bold-approval-c005df0fb8.media.strapiapp.com/cctv_camera_a0b88b2052.png", // Replace with actual image URL
//   },
//   {
//     name: "Swimming Pool",
//     img: "https://bold-approval-c005df0fb8.media.strapiapp.com/cctv_camera_a0b88b2052.png", // Replace with actual image URL
//   },
//   {
//     name: "CCTV Surveillance CCTV Surveillance CCTV Surveillance CCTV Surveillance CCTV Surveillance",
//     img: "https://bold-approval-c005df0fb8.media.strapiapp.com/cctv_camera_a0b88b2052.png",
//   },
//   {
//     name: "24/7 Security",
//     img: "https://bold-approval-c005df0fb8.media.strapiapp.com/cctv_camera_a0b88b2052.png", // Replace with actual image URL
//   },
//   {
//     name: "Swimming Pool",
//     img: "https://bold-approval-c005df0fb8.media.strapiapp.com/cctv_camera_a0b88b2052.png", // Replace with actual image URL
//   },
// ];

const Amenities = ({ projectData }) => {
  const { amenities } = projectData;
  const AMENITIES = extractAmenitiesAttributes(amenities?.data);

  return (
    <section className="row amenitiesBox">
      {AMENITIES.map((amenity, idx) => (
        <div key={idx} className="col-6 col-lg-3 col-md-4 p-2">
          <div className="amenitiesSubCategory">
            <img
              className="img-fluid"
              src={amenity.img}
              alt={amenity.alt}
              title={amenity.title}
              loading="lazy"
            />

            <div className="center-item amenitiesText">{amenity.name}</div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Amenities;

const extractAmenitiesAttributes = (amenitiesData = EMPTY_ARRAY) =>
  amenitiesData?.map((amenity) => {
    const { Icon, Title } = amenity?.attributes;
    const { url, alternativeText, caption } = Icon?.data?.attributes;

    return {
      img: url,
      alt: alternativeText,
      title: caption,
      name: Title,
    };
  });
