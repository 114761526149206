import NewTeamMembersContainer from "../about/aboutComponents/NewTeamMembersContainer";
import OurTeamTitle from "./ourTeamComponents/OurTeamTitle";

const OurTeam = () => {
  return (
    <div className="container">
      <OurTeamTitle />
      {/* <TeamMembersCard /> */}
      <NewTeamMembersContainer />
    </div>
  );
};
export default OurTeam;
