import "./emiCalculator.css";

const EmiCalculator = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div
          className="p-3 w-100 h-100"
          style={{
            border: "1px solid #FFFFFF",
            width: "100%",
            // height: "800px",
          }}
        >
          <iframe
            className="emiCalculator"
            src="https://emicalculator.net/widget/2.0/test.html"
            title="iFrame"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default EmiCalculator;
