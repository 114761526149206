import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

import Header from "../../globalComponents/header";
import HeaderMob from "../../mobileComponents/HeaderMob";
// import BreadCrumb from "./../../globalComponents/BreadCrumb/index";
import ProjectContactForm from "./components/ProjectContactExpertForm";
import ProjectImageSlider from "./components/ProjectImageSlider";
import ProjectDetailsBox from "./components/ProjectDetailsBox";
import MetaTagHelmet from "../../globalComponents/MetaTagHelmet";

import useDeviceSize from "./../../../hooks/useDeviceSize";
import useApiFetcher from "../../../hooks/useApiFetcher";
import { propertyParams } from "../../../helpers/params";
import { API_URL, getFullUrlBySlug } from "../../../assets/constants/apiUrls";
import { EMPTY_OBJECT } from "../../../assets/constants";
import "./projectDetailsPage.css";

const ProjectDetailsPage = () => {
  const mobileSize = useDeviceSize();
  const { propertyName: projectName } = useParams();
  const url = getFullUrlBySlug(API_URL.PROPERTIES, projectName);

  const [projectData, error, isLoading] = useApiFetcher(url);

  if (error) return <div>error</div>;

  if (isLoading || !projectData)
    return <ProjectPageLoader mobileSize={mobileSize} />;

  if (projectData.length <= 0) return <div>No Such Property Find</div>;

  const projectParams = propertyParams(projectData[0]);

  const {
    Meta_Title = "",
    Meta_Description = "",
    Meta_Link = "",
  } = projectParams || EMPTY_OBJECT;

  return (
    <section className="container-fluid">
      <MetaTagHelmet
        title={Meta_Title}
        description={Meta_Description}
        Meta_Link={Meta_Link}
      />
      {mobileSize ? (
        <HeaderMob />
      ) : (
        <div className="headerContainer">
          <Header className={"newHeaderStyle"} />
        </div>
      )}

      <div className="row mt-2 px-5">
        {/* <div className="col-md-12 my-2">
          <div className="center-item projectBreadCrumbBox">
            <BreadCrumb className="projectBreadCrumb" />
          </div>
        </div> */}
        <div className="col-md-9">
          <ProjectImageSlider projectData={projectParams} />
          <ProjectDetailsBox projectId={projectParams?.propertyId} />
        </div>
        <div className="col-md-3">
          <div className="projectDetailsForm">
            <ProjectContactForm projectData={projectParams} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetailsPage;

export const ProjectPageLoader = ({ mobileSize }) => {
  return (
    <section className="container-fluid">
      {mobileSize ? <HeaderMob /> : <Header className={"newHeaderStyle"} />}
      <div className="center-item" style={{ height: "50vh" }}>
        <CircularProgress />
      </div>
    </section>
  );
};
