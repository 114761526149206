const CAREER_FORM = {
  JOB_TITLE_OPTIONS: [
    "None",
    "Business Development Executive",
    "Associate Vice President",
    "Telecaller",
    "Sr Business Development Executive",
    "Assistant Manager",
  ],
  EXPERIENCE_OPTIONS: [
    "None",
    "Fresher",
    "1-3 Years",
    "4-6 Years",
    "More than 7 Years",
  ],
  CURRENT_PACKAGES_OPTIONS: [
    "Upto 3 lacs",
    "Upto 6 lacs",
    "7-10 lacs",
    "More than 10 lacs",
  ],
  LOCATIONS_OPTIONS: ["Noida", "Lucknow", "Rishikesh"],
};

export default CAREER_FORM;
