import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FaPlus, FaMinus } from "react-icons/fa";
import { memo, useState } from "react";

import ButtonDarkBlue from "../../../../globalComponents/molecules/ButtonDarkBlue";
import ConvertStringToHtml from "../../../../globalComponents/ConvertStringToHtml";
import { EMPTY_OBJECT } from "../../../../../assets/constants";

const CareerAccordionCard = memo(({ position, handleForm }) => {
  const { Position_Name, Position_Location, Position_Description } =
    position?.attributes || EMPTY_OBJECT;

  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) =>
    setExpanded(isExpanded ? panel : false);

  return (
    <>
      <Accordion
        expanded={expanded === Position_Name}
        onChange={handleChange(Position_Name)}
        className="my-2 p-0"
      >
        <AccordionSummary>
          <div className="d-flex w-100 justify-content-between">
            <div className="">
              <div style={{ fontSize: "18px", fontWeight: "600" }}>
                {Position_Name}
              </div>
              <div className="fs-6">{Position_Location}</div>
            </div>
            <div className="center-item">
              {expanded === Position_Name ? <FaMinus /> : <FaPlus />}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="fullPositionDesc">
            <ConvertStringToHtml htmlData={Position_Description} />
          </div>
          <div>
            <ButtonDarkBlue
              className="w-25 rounded-1"
              name="Apply"
              onClick={handleForm}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
});

export default CareerAccordionCard;
