import axios from "axios";
import { getFullUrl } from "../../../assets/constants/apiUrls";
import { getMethod } from "../../../services";
import { EMPTY_ARRAY } from "../../../assets/constants";

export const handleViewMore = async (projectId) => {
  const ipAddress = await fetchIpAddress();
  if (!ipAddress) {
    console.error("Unable to fetch IP address");
    return;
  }

  try {
    const existingIpAddresses = await fetchViewData(projectId);

    if (!existingIpAddresses.includes(ipAddress)) {
      existingIpAddresses.push(ipAddress);

      const fullUrl = getFullUrl("/api/project-views");
      await axios.post(fullUrl, {
        data: {
          projectId: projectId,
          ipAddress: String(ipAddress),
          viewCount: existingIpAddresses.length,
        },
      });
    } else {
      console.log(
        "IP address already exists. Current view count",
        existingIpAddresses.length
      );
    }
  } catch (error) {
    console.error(
      "Failed to update view data:",
      error.response ? error.response.data : error.message
    );
  }
};

export const fetchViewData = async (projectId) => {
  try {
    const fullUrl = getFullUrl(
      `/api/project-view?filters[projectId][$eq]=${projectId}`
    );
    const response = await axios.get(fullUrl);
    const ipAddressesArray =
      response.data.data?.[0]?.attributes?.ipAddress || EMPTY_ARRAY;
    const flattenedIpArray = ipAddressesArray.flat();
    const cleanedIpArray = flattenedIpArray.filter((ip) => ip.trim() !== "");
    return cleanedIpArray;
  } catch (error) {
    console.error(
      "Failed to fetch view data:",
      error.response ? error.response.data : error.message
    );
    return [];
  }
};

async function fetchIpAddress() {
  const ipData = await getMethod("https://api.ipify.org/?format=json");
  return ipData?.ip;
}
