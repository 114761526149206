export const validateForm = (formValues) => {
  const errors = {};
  if (!formValues.name.trim()) {
    errors.name = "Name is required";
  }

  if (!formValues.email.trim()) {
    errors.email = "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
    errors.email = "Email is invalid";
  }

  if (!formValues.phone.trim()) {
    errors.phone = "Phone Number is required";
  } else if (!/^\d{10}$/.test(formValues.phone)) {
    errors.phone = "Phone Number must be 10 digits";
  }

  return errors;
};
