import { EMPTY_ARRAY } from "../../../../assets/constants";

const Specifications = ({ projectData }) => {
  const specifications = projectData?.Specifications || EMPTY_ARRAY;

  if (specifications.length === 0) return null;
  if (!specifications) return null;

  const boxShadow = {
    border: "1px solid rgba(0, 0, 0, 0.1)",
  };

  const titleCss = {
    fontWeight: "500",
    color: "#ef750f",
  };

  const descriptionCss = {
    display: "flex",
    alignItem: "center",
    color: "#888",
  };

  const specificationContainer = {
    maxHeight: "400px",
    overflowY: "auto",
  };

  return (
    <section style={specificationContainer}>
      {specifications?.map((specification, idx) => {
        const { Title: title = "", Descriptions: description = "" } =
          specification;

        return (
          <div
            className="row px-0 mx-0 py-2 align-item-center my-2 rounded-2"
            key={idx}
            style={boxShadow}
          >
            <div className="col-md-4" style={titleCss}>
              {title}
            </div>
            <div className="col-md-8" style={descriptionCss}>
              {description}
              {/* Orion One32 is a commercial property in sector 132, Noida, with a */}
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default Specifications;
