import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

// import MetaTagHelmet from "../../globalComponents/MetaTagHelmet/MetaTagHelmet";
import BreadCrumb from "../../globalComponents/BreadCrumb";
import Heading from "../../globalComponents/molecules/Heading";
import MetaTagHelmet from "../../globalComponents/MetaTagHelmet";
import PaginationCustom from "../../globalComponents/PaginationCustom/PaginationCustom";
import PropertyForm from "./PropertyForm";
import PropertyCard from "./propertyCard";
import PropertyPageToolBar from "./PropertyPageToolBar";
import Loader from "./../../Loader";

import useApiFetcher from "./../../../hooks/useApiFetcher";
// import useMetaTags from "../../../hooks/useMetaTags";
import { slugToText } from "../../../services/slug";

import {
  getFullUrl,
  generateSearchedBarUrl,
  getSortQueryByField,
  API_URL,
} from "../../../assets/constants/apiUrls";
import useDeviceSize from "../../../hooks/useDeviceSize";
import useScrollOnTop from "../../../hooks/useScrollOnTop";
import URL, { URL_PARAMS } from "../../../assets/constants/url";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "./../../../assets/constants/index";
import { SORTED_BY_OPTIONS } from "../../../assets/constants/filters";
import useMetaTagsInArray from "../../../hooks/useMetaTagsInArray";
import "./cityPages.css";

// const urlForAllProperties = getFullUrl(API_URL.PROPERTIES + API_URL.POPULATE);

const CityPages = ({ setCityName }) => {
  // const CITY_PAGE_API = {
  //   NEW_LAUNCHES: `${API_URL?.PROPERTIES}?filters[newLaunches]=true`,
  //   EXCLUSIVE_PROPERTY: `${API_URL?.PROPERTIES}?filters[exclusive]=true`,
  //   CATEGORY: () =>
  //     `${
  //       API_URL.PROPERTIES
  //     }?filters[$and][0][property_type][Title][$eqi]=${slugToText(
  //       category
  //     )}&populate=*`,
  // };
  const isMobile = useDeviceSize();
  let { city: selectedCity } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const category = searchParams.get(URL_PARAMS.CATEGORY);
  const propertyType = searchParams.get(URL_PARAMS.PROPERTY_TYPE);
  const exclusiveProperty = searchParams.get(URL_PARAMS.EXCLUSIVE_PROPERTY);
  const newLaunches = searchParams.get(URL_PARAMS.NEW_LAUNCHES);
  const searchTerm = searchParams.get(URL_PARAMS.SEARCH_TERM);
  const buildingType = searchParams.get(URL_PARAMS.BUILDING_TYPE);
  const pageNumber = searchParams.get("page") || 1;

  useScrollOnTop(670, 0, location?.pathname + location?.search);

  const [filterBySortByOrder, setSortOrder] = useState(null);
  // const [filterBypropertyType, setFilterByPropertyType] = useState(null);
  const [apiUrl, setApiUrl] = useState(null);

  const [allCities] = useApiFetcher(getFullUrl(API_URL.CITY_NAME));

  const { title, description, link } = useMetaTagsInArray(
    `/api/create-cities?filters[City_Name][$eqi]=${selectedCity}`
  );

  useEffect(() => {
    if (allCities && selectedCity) {
      const isCityExist = allCities?.find(
        (city) =>
          city?.attributes?.City_Name.toLowerCase() ===
          slugToText(selectedCity.toLowerCase())
      );
      // setInitialMeta("city exist");
      setCityName(selectedCity);

      if (!isCityExist) {
        navigate("/404");
      }
    }
  }, [allCities, selectedCity, navigate, setCityName]);

  useEffect(() => {
    const { pathname, state } = location;
    let initialApiUrl;

    if (pathname === URL.SEARCH && state) {
      const { cityName, selectType, searchBarValue, budget } =
        state || EMPTY_OBJECT;

      initialApiUrl =
        generateSearchedBarUrl(
          cityName || EMPTY_ARRAY,
          selectType || EMPTY_ARRAY,
          searchBarValue || EMPTY_OBJECT,
          budget || EMPTY_OBJECT
        ) + "&sort=projectSequence:ASC";
    }

    // if (pathname.includes(URL.SEARCH) && propertyName) {
    //   initialApiUrl = generateSearchedBarUrl(EMPTY_ARRAY, [propertyName]);
    // }
    if (selectedCity && selectedCity !== pathname.includes(URL.SEARCH)) {
      initialApiUrl =
        generateSearchedBarUrl([slugToText(selectedCity)]) +
        "&sort=projectSequence:ASC&";
    }

    if (searchParams && category) {
      initialApiUrl = getFullUrl(
        `${
          API_URL.PROPERTIES
        }?filters[$and][0][property_type][Title][$eqi]=${slugToText(
          category
        )}&populate=*`
      );
    }

    if (searchParams && propertyType) {
      initialApiUrl = getFullUrl(
        `/api/projects?filters[sub_categories][Title][$eqi]=${slugToText(
          propertyType
        )}&populate=*&sort=projectSequence:ASC&`
      );
    }

    if (searchParams && buildingType) {
      if (location?.state) {
        initialApiUrl =
          generateSearchedBarUrl(
            location?.state.cityName || EMPTY_ARRAY,
            location?.state?.selectType || EMPTY_ARRAY,
            location?.state?.searchBarValue || EMPTY_OBJECT,
            location?.state?.budget || EMPTY_OBJECT,
            buildingType
          ) + "&sort=projectSequence:ASC";
      } else {
        initialApiUrl = getFullUrl(
          `/api/projects?filters[category][Title][$eqi]=${slugToText(
            buildingType
          )}&populate=*&sort=projectSequence:ASC`
        );
      }
    }

    if (searchParams && exclusiveProperty) {
      initialApiUrl = getFullUrl(
        `${API_URL?.PROPERTIES}?filters[exclusive]=true&populate=*&sort=projectSequence:ASC`
      );
    }

    if (searchParams && newLaunches) {
      initialApiUrl = getFullUrl(
        `${API_URL?.PROPERTIES}?filters[newLaunches]=true&populate=*&sort=projectSequence:ASC`
      );
    }

    if (searchParams && searchTerm) {
      initialApiUrl = getFullUrl(
        `${API_URL?.PROPERTIES}?filters[Slug_Url]=${searchTerm}&populate=*&sort=projectSequence:ASC`
      );
    }

    setApiUrl(`${initialApiUrl}&pagination[page]=${pageNumber}`);
  }, [
    pageNumber,
    selectedCity,
    location,
    category,
    searchParams,
    searchTerm,
    propertyType,
    buildingType,
    exclusiveProperty,
    newLaunches,
  ]);

  useEffect(() => {
    if (filterBySortByOrder) {
      let sortedParams;

      if (filterBySortByOrder === SORTED_BY_OPTIONS.LOW_PRICE) {
        sortedParams = { sortBy: "Min_Price", order: "ASC" };
      }
      if (filterBySortByOrder === SORTED_BY_OPTIONS.HIGH_PRICE) {
        sortedParams = { sortBy: "Min_Price", order: "DESC" };
      }

      if (filterBySortByOrder === SORTED_BY_OPTIONS.A_TO_Z) {
        sortedParams = { sortBy: "Project_Name", order: "ASC" };
      }

      const params = { ...sortedParams, dataLimit: 20 };

      const sortedField = getSortQueryByField(params);

      setApiUrl((prevUrl) => {
        const currentPrevUrl = prevUrl.replace("sort=projectSequence:ASC", "");
        return `${currentPrevUrl}${sortedField}`;
      });
    }
  }, [filterBySortByOrder]);

  // `${apiUrl}${order ? "" : "&pagination[pageSize]=20"}`
  const [properties, error, isLoading, pagination] = useApiFetcher(apiUrl);

  if (error) return <div>{error?.message}</div>;
  if (!properties || isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  const { pageCount } = pagination;
  const priorityProperty = properties;
  // filterBySortByOrder
  //   ? properties
  // : getPropertyWithPriority(properties);
  return (
    <>
      <MetaTagHelmet title={title} description={description} link={link} />
      <BreadCrumb />
      <section className="container-fluid cityPageContainer">
        <div className="row">
          <div className="col-md-9">
            <PropertyPageToolBar
              setOrder={setSortOrder}
              selectedCity={selectedCity}
              properties={properties}
            />
          </div>

          <div className="col-lg-9 col-md-12  col-sm-12 col-xs-12 cityPageLeftSideContainer">
            {properties.length ? (
              <div className=" col-lg-12 col-md-12  col-sm-12 col-xs-12  propertyCardsContainer">
                {priorityProperty?.map((property) => (
                  <PropertyCard
                    key={property?.id}
                    // cityName={selectedCity}
                    propertyInfo={property}
                  />
                ))}
              </div>
            ) : (
              <div className="center-item h-100">
                <Heading
                  className="col-lg-12 col-md-12  col-sm-12 col-xs-12 center-item h-100"
                  text="Sorry, no results found!"
                  lineHeight="4rem"
                  color="#ef750f"
                  fontSize={isMobile ? "1.5rem" : "2rem"}
                />
              </div>
            )}
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
            <PropertyForm className={"p-2"} />
          </div>
        </div>
        <div className="py-3 center-item">
          <PaginationCustom count={pageCount} state={location?.state} />
        </div>
      </section>
    </>
  );
};

export default CityPages;
