const ProjectSliderCard = ({ imageData }) => {
  const { img, alt, title } = imageData;
  return (
    <div className="projectSlider">
      <img
        src={img}
        alt={alt}
        className="img-fluid"
        loading="lazy"
        title={title}
      />
    </div>
  );
};

export default ProjectSliderCard;
