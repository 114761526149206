import EmiCalculator from "../EmiCalculator";

const EmiCalculatorPage = () => {
  return (
    <div className="container  p-3">
      <EmiCalculator />
    </div>
  );
};

export default EmiCalculatorPage;
