import { Link, useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa6";
import "./breadCrumb.css";

const BreadCrumb = ({ className }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((eachPath) => eachPath);

  return (
    <div className={className}>
      <div className={`breadcrumb`}>
        <Link to="/">Home</Link>
        {pathnames.map((name, idx) => {
          const routeTo = `/${pathnames.slice(0, idx + 1).join("/")}`;
          const isLast = idx === pathnames.length - 1;

          return (
            <span key={name} className="breadCrumb-item">
              <FaChevronRight className="chevron-icon" />
              {!isLast ? (
                <Link to={routeTo}>{name}</Link>
              ) : (
                <span className="currentRoute">{name}</span>
              )}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default BreadCrumb;
