export const CITY_NAME = {
  NOIDA: "Noida",
  DELHI: "Delhi",
  GREATER_NOIDA: "Greater Noida",
  GURUGRAM: "Gurugram",
  GHAZIABAD: "Ghaziabad",
  LUCKNOW: "Lucknow",
  FARIDABAD: "Faridabad",
  MORADABAD: "Moradabad",
  RISHIKESH: "Rishikesh",
  PUNE: "Pune",
  BENGALURU: "Bengaluru",
};

export const {
  NOIDA,
  DELHI,
  GREATER_NOIDA,
  GHAZIABAD,
  GURUGRAM,
  LUCKNOW,
  MORADABAD,
  RISHIKESH,
  PUNE,
  BENGALURU,
} = CITY_NAME;

export const CITIES = {
  REAL_ESTATE: [
    NOIDA,
    DELHI,
    GREATER_NOIDA,
    GHAZIABAD,
    LUCKNOW,
    GURUGRAM,
    MORADABAD,
    RISHIKESH,
    PUNE,
    BENGALURU,
  ],
  POPULAR_SEARCHES: [GHAZIABAD, NOIDA, GREATER_NOIDA, GURUGRAM],
};

export const ALL_CITIES = [...CITIES.REAL_ESTATE];

export const POPULAR_SEARCHES = [
  {
    propertyType: "Residential",
    city: "Ghaziabad=",
  },
  {
    propertyType: "Residential",
    city: "Noida",
  },
  {
    propertyType: "Residential",
    city: "Greater Noida",
  },
  {
    propertyType: "Commercial",
    city: "Gurugram",
  },
];
