import axios from "axios";
import { useMemo, useCallback, useEffect, useState } from "react";

import TextBg from "../../../globalComponents/molecules/TextBg";
import CareerFormPopUp from "./CareerFormPopUp";

// import { SORTED_BY_CAREER } from "../../../../assets/constants/filters";
// import { CAREER_POSITIONS } from "./../../../../assets/constants/career";
import { getFullUrl } from "../../../../assets/constants/apiUrls";
import "./position.css";
import CareerAccordionCardList from "./CareerAccordionCardList";

const POSITION_TABS = {
  ALL_BTN: "All",
};

const Position = () => {
  const [filter, setFilter] = useState(POSITION_TABS.ALL_BTN);
  const [positions, setPositions] = useState([]);
  const [isCareerFormOpen, setIsCareerFormOpen] = useState(false);
  const [careerModal, setCareerModal] = useState(null);

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const response = await axios.get(getFullUrl("/api/career-positions"));
        setPositions(response.data.data);
      } catch (error) {
        console.error("Error fetching positions: ", error);
      }
    };

    fetchPositions();
  }, []);

  const filteredPositions = useMemo(() => {
    return filter === POSITION_TABS.ALL_BTN
      ? positions
      : positions.filter((position) =>
          position.attributes.Position_Name.includes(filter)
        );
  }, [filter, positions]);

  const PositionButton = useMemo(() => {
    return [
      POSITION_TABS.ALL_BTN,
      ...new Set(
        positions.map((position) => position.attributes.Position_Name)
      ),
    ];
  }, [positions]);

  const handleJobForm = useCallback((selectedJobTitle) => {
    setIsCareerFormOpen((prevProps) => !prevProps);
    setCareerModal(selectedJobTitle);
  }, []);

  return (
    <div className="mb-5">
      <TextBg
        text={"Open Positions"}
        fontWeight={800}
        fontSize="4rem"
        className="py-3"
      />
      <div className="">
        <div className="filterTabs">
          {PositionButton.map((filterOption, idx) => (
            <button
              key={idx}
              onClick={() => setFilter(filterOption)}
              className="job-tags"
              style={{
                background:
                  filter === filterOption ? "black" : "rgb(243 117 53)",
                borderRadius: ".25rem",
                padding: ".75rem 1.25rem",
                color: "#fff",
                textDecoration: "none",
                fontSize: 12,
                cursor: "pointer",
                margin: ".5rem",
                textWrap: "nowrap",
              }}
            >
              {filterOption}
            </button>
          ))}
        </div>
        <div className="row">
          {/* <div className="py-4">
            <span className="px-2 fs-4 border-bottom border-black">
              Positions
            </span>
          </div> */}

          <CareerAccordionCardList
            filteredPositions={filteredPositions}
            callBackHandleJobForm={handleJobForm}
          />

          {isCareerFormOpen && (
            <CareerFormPopUp
              isOpen={isCareerFormOpen}
              onClose={handleJobForm}
              formName={careerModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Position;
