import ProjectSlider from "./ProjectImageSlider/ProjectSlider";

import { extractImageAttributes } from "../helper/extractAttributes";

const FloorPlans = ({ projectData }) => {
  const floorPlansImages = extractImageAttributes(
    projectData?.Floor_Plan?.Images?.data
  );

  return (
    <div className="">
      <ProjectSlider imagesData={floorPlansImages} />
    </div>
  );
};

export default FloorPlans;
