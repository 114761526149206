import { COMPANY_SECONDARY_LOGO } from "../../../assets/constants/companyLogos";

function CompanyLogoSection({ onClose, hideCloseBtn }) {
  return (
    <div className="d-flex pb-3 justify-content-between w-75">
      <div className="logoOnLoginForm">
        <img
          alt="company-logo"
          src={COMPANY_SECONDARY_LOGO}
          className="w-100"
        />
      </div>
      {hideCloseBtn && (
        <button onClick={onClose} className="closeBtn">
          X
        </button>
      )}
    </div>
  );
}

export default CompanyLogoSection;
