import ProjectDetailsSection from "./ProjectDetailsSection";
import ProjectSlider from "./ProjectSlider";

import { extractImageAttributes } from "../../helper/extractAttributes";
import { EMPTY_OBJECT } from "../../../../../assets/constants";

import "./projectDetailsSection.css";

const ProjectImageSlider = ({ projectData }) => {
  const imageData = extractImageAttributes(projectData?.Image?.data);
  const projectDetail = extractProjectParams(projectData);

  return (
    <section className="projectImageSliderBox">
      <ProjectSlider imagesData={imageData} />

      <div className="project-details">
        <ProjectDetailsSection projectDetail={projectDetail} />
      </div>
    </section>
  );
};

export default ProjectImageSlider;

function extractProjectParams(projectData = EMPTY_OBJECT) {
  const {
    Project_Name = "",
    Address = "",
    minPrice = "",
    maxPrice = "",
    RegNo = "",
    Project_Configuration = "",
    propertyId,
  } = projectData;

  return {
    projectName: Project_Name,
    address: Address,
    configuration: Project_Configuration,
    regNo: RegNo,
    minPrice,
    maxPrice,
    propertyId,
  };
}
